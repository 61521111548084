import { Autocomplete, Box, Button, Container, FormControl, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PrintIcon from '@mui/icons-material/Print';

import { Card, CardContent, List, ListItem, ListItemText, Divider } from "@mui/material";


export default function GratuiteStats() {
  const userData = useSelector(state => state.userData);
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const [Progession, setProgession] = useState(false);
  const [listPromo, setlistPromo] = useState([]);
  const [valCategorie, setvalCategorie] = useState('');
  const [listProduits, setListProduits] = useState([]);
  const [valProduit, setValProduit] = useState(null);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const todayDate = `${year}-${month}-${day}`;

  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [selectedDate2, setSelectedDate2] = useState(todayDate);

  const handleCategorieChange = (event) => {
    setvalCategorie(event.target.value);
  };

  const handleProduitChange = (event, newValue) => {

    if (newValue) {
        setValProduit(newValue.id);
    } else {
      setValProduit(null);
    }
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));   
    

      function ShowList() {
        if(listPromo.length > 0){
          return listPromo.map(Item => {
            return(
              <StyledTableRow key={Item._id}>
              <StyledTableCell component="th" scope="row">
                {Item.produit}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Item.total_unite}
              </StyledTableCell>
            </StyledTableRow>
            )

          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={2} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

          const formatDateToIso = (dateString) => {
            const formattedDate = new Date(`${dateString}T00:00:00Z`);
            return formattedDate.toISOString();
          };


const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const handleDateChange2 = (event) => {
      setSelectedDate2(event.target.value);
    };

    const getGratuite=async () => {
      setProgession(true);
      const Debut = formatDateToIso(selectedDate)
      const fin = formatDateToIso(selectedDate2)
      try {
      const response = await axios.post(`${apiUrl}statistiques/gratuite/${id}/${apitoken}/${valCategorie}/${valProduit}/${Debut}/${fin}`,
        {
          timeout: 20000,
        }
      );
      setlistPromo(response.data);
      }
   catch (error) { 
  }
  setProgession(false);
  }

  const downloadCSV = () => {
    // Créez les en-têtes du CSV
    let csv = "Nom,Prénom,Produit,Total d'unités\n";
    
    // Parcourez les données et ajoutez chaque ligne
    listPromo.forEach(client => {
      client.promotions.forEach(promo => {
        csv += `${client.client_nom},${client.client_prenom},${promo.produit},${promo.total_unite}\n`;
      });
      csv += "\n";
    });

    // Créez un blob à partir du contenu CSV
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Créez un lien de téléchargement et cliquez dessus
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `promotions_${valCategorie}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getProduits=async () => {

    try {
    const response = await axios.post(`${apiUrl}produits/${id}/${apitoken}`,
      {
        timeout: 20000,
      }
    );

    const formattedProduits = [
      { label: "Tout", id: 0 },
      ...response.data.map(item => ({
        label: `${item.designation}`,
        id: item._id
      }))
    ];

    setListProduits(formattedProduits)
    }
 catch (error) {

}

}

      useEffect(() =>{
        getGratuite();
        },[selectedDate,selectedDate2,valCategorie,valProduit]);

        useEffect(() =>{
          getProduits();
          },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Récap. Gratuité
        </Typography>


        <Box display="flex" width="100%" mt={5}>



        <Box mt={3}>
         <FormControl sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={valCategorie}
         label="Catégorie"
         onChange={handleCategorieChange}
       >
        <MenuItem value="AG">AG</MenuItem>
        <MenuItem value="Café">Café</MenuItem>
        <MenuItem value="Restaurant">Restaurant</MenuItem>
        <MenuItem value="Dépôt de lait">Dépôt de lait</MenuItem>
        <MenuItem value="Kiosque">Kiosque</MenuItem>
        <MenuItem value="Boulangerie">Boulangerie</MenuItem>

         </Select>
         </FormControl>
</Box>

<Box mt={3} ml={2} width={250}>
            <Autocomplete
      disablePortal
      options={listProduits}
      onChange={handleProduitChange}
     fullWidth
      renderInput={(params) => <TextField {...params} label="Produits" />}
    />
            </Box>

    <Box mt={2} ml={2} width={160}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Du"
            type="date"
            fullWidth
            variant="outlined"
            value={selectedDate}
            onChange={handleDateChange}
          />
          </Box>

          <Box mt={2} ml={2} width={160}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Au"
            type="date"
            fullWidth
            variant="outlined"
            value={selectedDate2}
            onChange={handleDateChange2}
          />

          </Box>
      
          <Box style={{ marginLeft: 'auto' }} mt={4}>
        <Button variant="contained" size='large' color="success" startIcon={<PrintIcon/>} onClick={downloadCSV} >
    Exporter
  </Button>   
    </Box>
        </Box>

        <Typography sx={{ marginTop: '20px' }} variant="h5" align='center' color="secondary">
        Gratuité {valCategorie}
        </Typography>

          <Box mt={5}>
        {listPromo.map((client, index) => (
        <Card key={index} style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h6">{client.client_nom} {client.client_prenom}</Typography>
            <List>
              {client.promotions.map((promo, idx) => (
                <div key={idx}>
                  <ListItem>
                    <ListItemText
                      primary={`Produit: ${promo.produit}`}
                      secondary={`Total d'unités: ${promo.total_unite}`}
                    />
                  </ListItem>
                  {idx < client.promotions.length - 1 && <Divider />}
                </div>
              ))}
            </List>
          </CardContent>
        </Card>
      ))}
      </Box>
    
        </Container>
  )
}
