import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Chip, Typography } from '@mui/material';

export default function ApercuVentes({Show,toggleApercu ,currentVente}) {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
    
      function ShowPromoList(){
        if(currentVente.promotions.length>0){
          return currentVente.promotions.map(promo =>{
            return(
              <StyledTableRow key={promo._id}>
              <StyledTableCell component="th" scope="row">
              <Typography variant="h6" color="secondary">
              {promo.designation}
              </Typography>
              <Box>
              <Chip label={`PACKS : ${promo.qtepromo}`} />
                
              </Box>
              </StyledTableCell>
              </StyledTableRow>       
            )
          })

        }
        else {
          return (
            <StyledTableRow>
            <StyledTableCell align='center'  component="th" scope="row">
            Aucune promotion
            </StyledTableCell>
          </StyledTableRow>
          )
        }
      }
      function ShowList() {

        const listFiltre = currentVente.produits?.filter(chargement => chargement.pack>0 || chargement.unite >0 )
        return(
            listFiltre.map(chargement =>{
                return(

                    <StyledTableRow key={chargement._id}>
                    <StyledTableCell component="th" scope="row">
                    <Typography variant="h6" color="secondary">
                    {chargement.designation}
                    </Typography>
                    <Box>
                    <Chip label={`PACKS : ${chargement.pack}`} />
                    <Chip sx={{marginLeft:'5px'}} label={`UNITES : ${chargement.unite}`} />
                      
                    </Box>
                    </StyledTableCell>
                    </StyledTableRow>
                )
            })
        )
    }
  return (
    <Dialog
    open={Show}
    onClose={toggleApercu}
    >
    <DialogTitle align='center'>Aperçu</DialogTitle>
    <DialogContent>
    <DialogContentText align='center'>
          Liste des produits
          </DialogContentText>
          <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table sx={{ width: 400 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Produits</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
          <StyledTableRow>
          <StyledTableCell component="th" scope="row" align="center">
          <Typography variant="h7" align='center' color="secondary">
            TOTAL : {currentVente.total} Dhs
            </Typography>
            </StyledTableCell>
        </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          <Table sx={{ width: 400 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Promotions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <ShowPromoList/>
            </TableBody>
          </Table>
        </TableContainer>
    </DialogContent>
    <DialogActions>
    <Button color="error" onClick={toggleApercu}>Fermer</Button>
    </DialogActions>
    </Dialog>   
  )
}