import { Autocomplete, Box,  Container,  FormControl,  InputLabel,  MenuItem,  Select,  Skeleton,  Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import {  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

function AnalyseVendeur() {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));   

    const userData = useSelector(state => state.userData);
    const [Chargement, setChargement] = useState(false);
    const [listSecteur, setListSecteur] = useState([]);
    const [ListVendeur, setListVendeur] = useState([]);
    const [valVendeurs, setvalVendeurs] = useState('');
    const [valSecteur, setvalSecteur] = useState(null);
    const [ListeVentes, setListeVentes] = useState([]);

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const todayDate = `${year}-${month}`;

    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [selectedDate2, setSelectedDate2] = useState(todayDate);
    
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
      };
      const handleDateChange2 = (event) => {
        setSelectedDate2(event.target.value);
      };

    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleVendeurChange = (event) => {
        setvalVendeurs(event.target.value);
      };
    
      const handleSecteurChange = (event, newValue) => {
        if (newValue) {
            setvalSecteur(newValue.id);
        } else {
            setvalSecteur(null);
        }
      };

      function ShowVentes() {
        if(ListeVentes.length > 0){
          return ListeVentes.map(Item => {
            return(
              <StyledTableRow key={Item._id}>
              <StyledTableCell component="th" scope="row">
              {Item.date}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Item.total_ventes.toFixed(2)} Dhs
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Item.count}
              </StyledTableCell>
            </StyledTableRow>
            )
  
          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={3} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

    const getSecteurs=async () => {
        try {
        const response = await axios.post(`${apiUrl}secteurs/${id}/${apitoken}`,
          {
            timeout: 20000,
          }

        );
        const formattedSecteur = [
          { label: "Tout", id: 0 },
          ...response.data.map(item => ({
            label: `${item.designation}`,
            id: item._id
          }))
        ];
        setListSecteur(formattedSecteur)
        }
     catch (error) {
      // navigate('/');    
    }
    }

    const debutMois= (date) => {
        const firstDayOfMonth = new Date(`${date}-01T00:00:00Z`).toISOString();
        return firstDayOfMonth
      }
  
      const finMois= (date) => {
        const endOfMonth = new Date(new Date(`${date}-01T00:00:00Z`).getFullYear(), new Date(`${date}-01T00:00:00Z`).getMonth() + 1, 0);
        endOfMonth.setHours(23, 59, 0, 0);
        const endOfMonthISO = endOfMonth.toISOString();
        return endOfMonthISO
      }

    const getVentes=async () => {
        setChargement(true);
        const debut = debutMois(selectedDate)
        const fin = finMois(selectedDate2)
        try {
        const response = await axios.post(`${apiUrl}statistiques/analysevendeur/ventes/${id}/${apitoken}/${valVendeurs}/${valSecteur}/${debut}/${fin}`,
          {
            timeout: 20000,
          }
  
        );
        setListeVentes(response.data);
        }
     catch (error) {
      // navigate('/');    
    }
    setChargement(false);
    }

    const getVendeurs=async () => {
        try {
        const response = await axios.post(`${apiUrl}vendeurs/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListVendeur(response.data);
        }
     catch (error) {
    //   navigate('/');    
    }
    }


    useEffect(() =>{
        getSecteurs();
        getVendeurs();
        },[]);
    
    
        useEffect(() =>{
            getVentes()
            },[valVendeurs,valSecteur,selectedDate,selectedDate2]);


  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>

<Typography mt={5} variant="h4" align='center' color="primary">
        Analyse des ventes
        </Typography>

        <Box display="flex" width="100%" mt={5}>

        <Box mt={1}>
        <FormControl  sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Vendeurs</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valVendeurs}
          label="Vendeurs"
          onChange={handleVendeurChange}
        >
          {ListVendeur.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.nom}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
        </Box>

        <Box mt={1} ml={2} width={200}>
            <Autocomplete
      disablePortal
      options={listSecteur}
      onChange={handleSecteurChange}
     fullWidth
      renderInput={(params) => <TextField {...params} label="Secteurs" />}
    />
            </Box>

            <Box  ml={2} width={200}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Depuis"
            type="month"
            fullWidth
            variant="outlined"
            value={selectedDate}
            onChange={handleDateChange}
          />
          </Box>

          <Box  ml={2} width={200}>
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Au"
            type="month"
            fullWidth
            variant="outlined"
            value={selectedDate2}
            onChange={handleDateChange2}
          />

          </Box>


        </Box>

        <Box mt={5} width='50%' sx={{ float: 'left' }}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
<ResponsiveContainer width="100%" height={400}>
<AreaChart data={ListeVentes}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* Première zone pour 'total_ventes' */}
        <Area type="monotone" dataKey="total_ventes" stroke="#8884d8" fill="#8884d8" name="Total Ventes" />
      </AreaChart>
    </ResponsiveContainer>
}

    </Box>

    <Box mt={5} width='50%' sx={{ float: 'left' }}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
<ResponsiveContainer width="100%" height={400}>
<AreaChart data={ListeVentes}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* Deuxième zone pour 'count', renommée en "Nombre de Visites" */}
        <Area type="monotone" dataKey="count" stroke="#82ca9d" fill="#82ca9d" name="Nombre de Visites" />
      </AreaChart>
    </ResponsiveContainer>
}

    </Box>
    <Box sx={{ clear: 'both' }} />

    {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :

        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table  sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Chiffre d'affaire</StyledTableCell>
            <StyledTableCell>Nombre de visites</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowVentes/>
        </TableBody>
      </Table>
    </TableContainer>

}   


    </Container>
  )
}

export default AnalyseVendeur