import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    modalShow : false,
    modalModifShow : false,
    list :[],
    listSecteur :[],
    ajoutOk : false,
    modifOk : false,
    current :[],
}
const Client = createSlice({
    name : 'ClientData',
    initialState,
    reducers :{
        showmodal:(state,action) =>{
            state.modalShow=action.payload
        },
        getList:(state,action) =>{
            state.list=action.payload
        },
        getsecteur:(state,action) =>{
            state.listSecteur=action.payload
        },
        current:(state,action) =>{
            state.current=action.payload
        },
        ajout:(state,action) =>{
            state.ajoutOk=action.payload
    },
        modif:(state,action) =>{
        state.modifOk=action.payload
},
        addOne:(state,action) =>{
            state.list.push(action.payload)
    },
        modalModifShow:(state,action) =>{
            state.modalModifShow=action.payload
},
    update: (state, action) => {

        const element = state.list.find(element => element._id === action.payload.idclient);
        if (element) {
            element.nom = action.payload.nom;
            element.prenom = action.payload.prenom;
            element.tel = action.payload.tel;
            element.adresse = action.payload.adresse;
            element.categorie = action.payload.categorie;
            element.secteur = action.payload.secteur;
            element.ville = action.payload.ville;
            element.ice = action.payload.ice;
            element.banque = action.payload.banque;
            element.compte = action.payload.compte;
    }
  },
  remove: (state, action) => {    
    const id = action.payload;
    state.list=state.list.filter(element => element._id !== id);
  }

    }
})
export const {showmodal,getList,ajout,modif,addOne,modalModifShow,update,current,remove,getsecteur} = Client.actions
export default Client.reducer;