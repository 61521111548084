import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    apercuShow : false,
    ajoutsuccess : false,
    modifsuccess : false,
    current : [],
    listVendeurs : [],
    listCamions : [],
}

const Chargements = createSlice({
    name : 'ChargementsData',
    initialState,
    reducers :{
        ajoutsuccess:(state,action) =>{
            state.ajoutsuccess=action.payload
        },
        modifsuccess:(state,action) =>{
            state.modifsuccess=action.payload
        },
        idchargement:(state,action) =>{
            state.current=action.payload
        },
        showapercu:(state,action) =>{
            state.apercuShow=action.payload
        },
        getvendeurs:(state,action) =>{
            state.listVendeurs=action.payload
        },
        getcamions:(state,action) =>{
            state.listCamions=action.payload
        },
    }
})

export const {ajoutsuccess,idchargement,modifsuccess,showapercu,getvendeurs,getcamions} = Chargements.actions
export default Chargements.reducer;