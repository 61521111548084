import { Alert, Box, Button,  Container, FormControl, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';

import { useSelector,useDispatch } from 'react-redux';
import { ajoutsuccess,showapercu,fournisseur } from "./commandeSlice";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

import Apercu from './Apercu';

export default function CommandeFournisseur() {

  const commandesData = useSelector(state => state.commandesData);
  const ajoutSuccess = commandesData.ajoutsuccess;
  const ListFournisseur = commandesData.listFournisseurs;
  const [Chargement, setChargement] = useState(false);
  const [listCommandes, setlistCommandes] = useState([]);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [Progession, setProgession] = useState(false);
  const [valFournisseur, setvalFournisseur] = useState('Tout');

  const userData = useSelector(state => state.userData)
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;


  const navigate = useNavigate();
  const dispatch= useDispatch();

  const handleFournisseurChange = (event) => {
    setvalFournisseur(event.target.value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajoutsuccess(false));
    setRemoveSuccess(false);
  };

  const formatDateToFrench = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  const listAchats=useRef([]);


  const suppression = async (idcommande)=> {
    setProgession(true)
    
    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}delete/commandefournisseur/${id}/${apitoken}/${idcommande}`,
          {
            timeout: 20000,
          }
        );
        if(response.data.status==="ok")
            {
                const newlist=listCommandes.filter(item => item._id !== idcommande);
                setlistCommandes(newlist);
                setRemoveSuccess(true);
            }
    } catch (error) {
      navigate('/');
    }
    setProgession(false)
}

const supprimer = (id) => {
  let result = window.confirm(`Supprimer cette commande ?`);
  if (result === true) {
      suppression(id);
  }
}

const ShowApercu=async (produits) => {
  listAchats.current = produits
  dispatch(showapercu(true))
}

const GetCommande = async (idcommande,date) =>{
  try {
      const dateFr=formatDateToFrench(date)
      const response = await axios.get(`${apiUrl}generer/commandesfournisseur/${id}/${apitoken}/${idcommande}`,{
        responseType: 'blob',
      });
      // Créez un lien pour télécharger le PDF
      const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',`Bon de commande du ${dateFr}.pdf`);
      document.body.appendChild(link);
      link.click();
  }
      catch (error) {
          console.error('Error generating fsortie:', error);
        }
}

function ShowList() {
  if(listCommandes.length > 0){
    let listFiltre=listCommandes
    if(valFournisseur!=="Tout") {
      listFiltre = listFiltre.filter(item => item.fournisseur["id"] === valFournisseur)
  }
    return listFiltre.map(Item => {
      return(
        <StyledTableRow key={Item._id}>
        <StyledTableCell component="th" scope="row">
          {Item.fournisseur.designation}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {Item.total} Dhs
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
        {Item.date!=="" ? formatDateToFrench(Item.date) : ''}
        </StyledTableCell>
        <StyledTableCell align="right">
        <Button variant="outlined" color='secondary' startIcon={<VisibilityIcon />} onClick={() => ShowApercu(Item.produits)}>
        Aperçu
        </Button>      
        <Button sx={{ marginLeft: '10px',width:'140px' }} variant="outlined" color='primary' startIcon={<PrintIcon />} onClick={() => GetCommande(Item._id,Item.date)}>
        Imprimer
        </Button> 
        <Button sx={{ marginLeft: '10px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Item._id)}>
         Supprimer
        </Button>
        </StyledTableCell>
      </StyledTableRow>
      )

    })
    }
    else {
      return (
        <StyledTableRow>
        <StyledTableCell align='center' colSpan={4} component="th" scope="row">
        Pas de données
        </StyledTableCell>
      </StyledTableRow>
      )
    }
    }

const GetCommandes=async () => {
  setChargement(true)
    try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await axios.post(`${apiUrl}commandesfournisseur/${id}/${apitoken}`,
      {
        timeout: 20000,
      }
    );
    setlistCommandes(response.data);
    }
  catch (error) {
    navigate('/');    
  }
  setChargement(false)
}

const getFournisseurs=async () => {
  try {
  const response = await axios.post(`${apiUrl}fournisseurs/${id}/${apitoken}`,
      {
        timeout: 20000,
      }
    );        dispatch(fournisseur(response.data));
  }
catch (error) {
  console.error('Error:', error);
}
}

const Ajouter=() => {
  navigate('/home/nouvellecommande');
  }

  useEffect(() =>{
    GetCommandes()
    getFournisseurs();
    },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Commandes fournisseur
        </Typography>

        <Box display="flex" width="100%" mt={5}>

<Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Fournisseur</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valFournisseur}
          label="Fournisseur"
          onChange={handleFournisseurChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {ListFournisseur.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
}
</Box>


            <Box style={{ marginLeft: 'auto' }}>

               {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :

        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={Ajouter} >
    Nouvelle commande
  </Button>  
} 
</Box>
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Fournisseur</StyledTableCell>
            <StyledTableCell>Total</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}

          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Commande ajoutée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Commande supprimée avec succès
        </Alert>
      </Snackbar>
      <Apercu Achats={listAchats} />
        </Container>
  )
}
