import { Alert, Box, Button, Chip, Container, FormControl, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useSelector,useDispatch } from 'react-redux';
import { ajoutsuccess,idachat,modifsuccess,showapercu,fournisseur } from "./AchatsSlice";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Apercu from './Apercu';

export default function Achats() {

  const achatsData = useSelector(state => state.achatsData);
  const ajoutSuccess = achatsData.ajoutsuccess;
  const modifSuccess = achatsData.modifsuccess;
  const ListFournisseur = achatsData.listFournisseurs;
  const [Chargement, setChargement] = useState(false);
  const [listachats, setListachats] = useState([]);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [rechercheEtat,setrechercheEtat] = useState('Tout');
  const [Progession, setProgession] = useState(false);
  const [valFournisseur, setvalFournisseur] = useState('Tout');

  const userData = useSelector(state => state.userData)
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;


  const fournisseurField=useRef();
  const navigate = useNavigate();
  const dispatch= useDispatch();

  const handleFournisseurChange = (event) => {
    setvalFournisseur(event.target.value);
  };
  const handleEtatChange = (event) => {
    setrechercheEtat(event.target.value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajoutsuccess(false));
    dispatch(modifsuccess(false));
    setRemoveSuccess(false);
  };

  const formatDateToFrench = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  const listAchats=useRef([]);

  const modifier = (id) => {
    const filtreachat = listachats.filter(list => list._id === id);
    dispatch(idachat(filtreachat));
    navigate('/home/editachat');
  }

  const suppression = async (idachat)=> {
    setProgession(true)
    
    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}delete/achat/${id}/${apitoken}/${idachat}`,
          {
            timeout: 20000,
          }
        );
        if(response.data.status==="ok")
            {
                const newlist=listachats.filter(achat => achat._id !== idachat);
                setListachats(newlist);
                setRemoveSuccess(true);
            }
    } catch (error) {
      navigate('/');
    }
    setProgession(false)
}

const supprimer = (id) => {
  let result = window.confirm(`Supprimer cet achat ?`);
  if (result === true) {
      suppression(id);
  }
}

const ShowApercu=async (idachat) => {
  setProgession(true)
  try {
  const apiUrl = process.env.REACT_APP_API_URL;
  const response = await axios.post(`${apiUrl}list/achat/${id}/${apitoken}/${idachat}`,
    {
      timeout: 20000,
    }
  );
  listAchats.current = response.data.produits
  dispatch(showapercu(true))
  }
  catch (error) {
      console.error('Error:', error);    
  }
  setProgession(false)
}



function ShowList() {
  if(listachats.length > 0){
    let listFiltre=listachats
    if(valFournisseur!=="Tout") {
      listFiltre = listFiltre.filter(achat => achat.fournisseur["id"] === valFournisseur)
  }
  if(rechercheEtat!=="Tout") {
    listFiltre = listFiltre.filter(achat => achat.etat === rechercheEtat)
}
    return listFiltre.map(Achat => {
      return(
        <StyledTableRow key={Achat._id}>
        <StyledTableCell component="th" scope="row">
          {Achat.fournisseur.designation}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {Achat.facture}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {Achat.montant} Dhs
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
        <Stack direction="row" spacing={1}>
          {Achat.etat === "Réglée" ?
          <Chip label="Réglée" color="success" />
          :
          <Chip label="Non Réglée" color="warning" />
          }
          </Stack>
          <Stack direction="row" spacing={1}></Stack>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
        {Achat.dateArrivage!=="" ? formatDateToFrench(Achat.dateArrivage) : ''}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {Achat.dateReglement!=="" ? formatDateToFrench(Achat.dateReglement) : ''}
        </StyledTableCell>
        <StyledTableCell align="right">
        <Button variant="outlined" color='secondary' startIcon={<VisibilityIcon />} onClick={() => ShowApercu(Achat._id)}>
        Aperçu
        </Button> 
        <Button sx={{ marginLeft: '10px' }} variant="outlined" color='primary' startIcon={<EditIcon />} onClick={() => modifier(Achat._id)}>
         Modifier
        </Button>      
        <Button sx={{ marginLeft: '10px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Achat._id)}>
         Supprimer
        </Button>
        </StyledTableCell>
      </StyledTableRow>
      )

    })
    }
    else {
      return (
        <StyledTableRow>
        <StyledTableCell align='center' colSpan={7} component="th" scope="row">
        Pas de données
        </StyledTableCell>
      </StyledTableRow>
      )
    }
    }

const GetAchats=async () => {
  setChargement(true)
    try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await axios.post(`${apiUrl}achats/${id}/${apitoken}`,
      {
        timeout: 20000,
      }
    );
    setListachats(response.data);
    }
  catch (error) {
    navigate('/');    
  }
  setChargement(false)
}

const getFournisseurs=async () => {
  try {
  const response = await axios.post(`${apiUrl}fournisseurs/${id}/${apitoken}`,
      {
        timeout: 20000,
      }
    );        dispatch(fournisseur(response.data));
  }
catch (error) {
  console.error('Error:', error);
}
}

const Ajouter=() => {
  navigate('/home/nouvelachat');
  }

  useEffect(() =>{
    GetAchats()
    getFournisseurs();
    },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Achats
        </Typography>

        <Box display="flex" width="100%" mt={5}>

<Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Fournisseur</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valFournisseur}
          label="Fournisseur"
          onChange={handleFournisseurChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {ListFournisseur.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
}
</Box>
<Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150,marginLeft:2 }}>
          <InputLabel id="demo-simple-select-label">Etat</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={rechercheEtat}
          label="Etat"
          onChange={handleEtatChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          <MenuItem value='Réglée'>Réglée</MenuItem>
          <MenuItem value='Non Réglée'>Non Réglée</MenuItem>
          </Select>
          </FormControl>
}
</Box>

            <Box style={{ marginLeft: 'auto' }}>

               {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :

        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={Ajouter} >
    Nouvel Achat
  </Button>  
} 
</Box>
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Fournisseur</StyledTableCell>
            <StyledTableCell>N° facture</StyledTableCell>
            <StyledTableCell>Montant</StyledTableCell>
            <StyledTableCell>Etat</StyledTableCell>
            <StyledTableCell>Date d'arrivage</StyledTableCell>
            <StyledTableCell>Date réglement</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}

          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Achat ajouté avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Achat modifié avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Achat supprimé avec succès
        </Alert>
      </Snackbar>
      <Apercu Achats={listAchats} />
        </Container>
  )
}
