import { Box, Chip, Container, Skeleton, Typography } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export default function PdaPromotions() {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const userData = useSelector(state => state.userData);

      const [Chargement, setChargement] = useState(false);
      const [listPromos, setlistPromos] = useState([]);
      const id = userData.id;
      const apitoken = userData.apitoken;
      const apiUrl = process.env.REACT_APP_API_URL;

      const navigate = useNavigate();

      function ShowList() {
        if(listPromos.length > 0){
          let listFiltre=listPromos
          return listFiltre.map(Promo => {
            return(
              <StyledTableRow key={Promo._id}>
              <StyledTableCell component="th" scope="row">
              <Chip variant="outlined" sx={{ marginRight: '10px' }} label={Promo.qtecible} />{Promo.prodCible.designation}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <Chip variant="outlined" sx={{ marginRight: '10px' }} label={Promo.qtepromo} />{Promo.prodPromo.designation}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <Box maxWidth={300} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {Promo.categories.map( item =>{
                  return(
                    <Chip label={item} variant="outlined" />
                  )
                }

                )
                }
                </Box>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Promo.remarque}
              </StyledTableCell>
            </StyledTableRow>
            )

          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={4} component="th" scope="row">
              Pas de promotions
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

          const getPromo=async () => {
            setChargement(true);
            try {
            const response = await axios.post(`${apiUrl}promos/${id}/${apitoken}`,
              {
                timeout: 20000,
              }
            );
            setlistPromos(response.data);
            }
         catch (error) {
          navigate('/');    
        }
        setChargement(false);
        }
      
        useEffect(() =>{
            getPromo();
            },[]);
  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Promotions
        </Typography>

        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Produit Cible</StyledTableCell>
            <StyledTableCell>Produit Promo</StyledTableCell>
            <StyledTableCell>Catégories</StyledTableCell>
            <StyledTableCell>Remarque</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}

    </Container>
  )
}
