import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import DialpadIcon from '@mui/icons-material/Dialpad';
import { Box, Button, Container, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import { useState,useRef } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import Cookies from 'js-cookie';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import { creation } from './userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Login() {


    const telField= useRef('');
    const passwordField = useRef('');

    const [chargement,setChargement] = useState(false);

    const [openAlert, setopenAlert] = useState(false);

    const [canSubmit, setCanSubmit] = useState(false);
    const refTurnstile = useRef(null);

    function generateRandomToken(length = 16) {
      // Crée un tableau d'octets aléatoires
      const array = new Uint8Array(length);
      window.crypto.getRandomValues(array);
    
      // Convertit les octets en une chaîne hexadécimale
      return Array.from(array, byte => ('0' + byte.toString(16)).slice(-2)).join('');
    }
    
    const dispatch= useDispatch();
    const navigate = useNavigate();

    const handlConnect =async () => {
      setChargement(true)
      const tel=telField.current.value.trim()
      const password=passwordField.current.value.trim()
      const token = refTurnstile.current.getResponse()
      const apitoken = generateRandomToken();
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}login`, {
            tel,
            password,
            token,
            apitoken,
        },
        {
          timeout: 20000,
        }
      );
      const forceTotp = response.data["forceTotp"]
      dispatch(creation(response.data))
      if(forceTotp==="Non"){
         navigate('/home');
         return
      }
      const iduser=response.data["_id"];
        const totp=response.data["totp"];
        const token2fa = Cookies.get('token');
        if(token2fa){
          const response2 = await axios.post(`${apiUrl}check/token/${iduser}/${token2fa}`,
            {
              timeout: 20000,
            }
          );
          if(response2.data.status==="ok"){
          navigate('/home');
          }
      else {
          if(totp===""){
              navigate('setup2fa');
          }
          else{
          navigate('secure');
      }
      }
      }
      else {
        if(totp===""){
            navigate('setup2fa');
        }
        else{
        navigate('secure');
    }
}
      } catch (error) {
        setopenAlert(true)
        setChargement(false)
        setCanSubmit(false)
        refTurnstile.current?.reset();
    }

    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setopenAlert(false);
      };

  return (
    <Container maxWidth="sm">
      
<Snackbar open={openAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Identifiant ou mot de passe incorrecte
        </Alert>
      </Snackbar>

<Box mt={5} >
    <Card >
    { chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
    <CardContent >

    <Typography variant="h4" align='center' color="primary">
          Identification
        </Typography>
        <Typography variant="h6" mt={2} mb={2} align='center' >
        Connectez vous à votre compte
        </Typography>
        <Divider/>
        <Box mt={4} >
        <TextField
        required
        inputRef={telField}
        id="input-with-icon-textfield"
        label="N° Téléphone"
        size='normal'
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DialpadIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
        </Box>

<Box mt={4} >
<TextField
        required
        inputRef={passwordField}
        id="input-with-icon-textfield"
        label="Mot de passe"
        type='password'
        size='normal'
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HttpsOutlinedIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
</Box>


<Box fullWidth mt={4} display="flex" alignItems="center" justifyContent="center">
<Turnstile
                    options={{
                        theme: 'light',
                        // size: 'compact',
                        language: 'fr',
                    }}
                    id='turnstile-1'
                    ref={refTurnstile}
                    siteKey='0x4AAAAAAASYjlyv4R1olnPH'
                    onSuccess={() => setCanSubmit(true)}
                     />
</Box>

<Box mt={4} >
  
    { canSubmit ?
      
      <Button variant="contained" fullWidth size='large' color="success" onClick={handlConnect}>
      Se connecter
    </Button>    
      
    :
<Button  disabled variant="contained" fullWidth size='large' color="success">
  Se connecter
</Button>
    }
  

</Box>

        </CardContent>
        </Card>
        </Box>

        </Container>
  )
}
