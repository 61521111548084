import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    modalShow : false,
    modalModifShow : false,
    list :[],
    ajoutOk : false,
    modifOk : false,
    current :[],
}
const Secteurs = createSlice({
    name : 'SecteursData',
    initialState,
    reducers :{
        showmodal:(state,action) =>{
            state.modalShow=action.payload
        },
        getList:(state,action) =>{
            state.list=action.payload
        },
        current:(state,action) =>{
            state.current=action.payload
        },
        ajout:(state,action) =>{
            state.ajoutOk=action.payload
    },
        modif:(state,action) =>{
        state.modifOk=action.payload
},
        addOne:(state,action) =>{
            state.list.push(action.payload)
    },
        modalModifShow:(state,action) =>{
            state.modalModifShow=action.payload
},
    update: (state, action) => {

        const element = state.list.find(element => element._id === action.payload.idsecteur);
        if (element) {
            element.designation = action.payload.designation;
    }
  },
  remove: (state, action) => {    
    const id = action.payload;
    state.list=state.list.filter(element => element._id !== id);
  }

    }
})
export const {showmodal,getList,ajout,modif,addOne,modalModifShow,update,current,remove} = Secteurs.actions
export default Secteurs.reducer;