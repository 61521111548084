import { Alert, Box, Button, Container, FormControl, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';

import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,getList,remove,modalModifShow,current,modif,getListMarque,getListCat} from './ProduitsSlice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modifier from './Modifier';
import Ajouter from './Ajouter';

import SearchIcon from '@mui/icons-material/Search';

export default function Produits() {
  const userData = useSelector(state => state.userData);
  const [Chargement, setChargement] = useState(false);
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const produitsData=useSelector(state => state.produitsData);
  const listProduits=produitsData.list
  const listCat = produitsData.listCat;
  const listMarque = produitsData.listMarque;
  const ajoutSuccess = produitsData.ajoutOk
  const modifSuccess = produitsData.modifOk
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [Progession, setProgession] = useState(false);
  const [rechercheInput,setRchercheInput] = useState('');
  const [valMarque, setMarque] = useState('Tout');
  const [valCategorie, setCategorie] = useState('Tout');

  const rechercheField = useRef('')

  const dispatch= useDispatch();
  const navigate = useNavigate();

  const handleMarqueChange = (event) => {
    setMarque(event.target.value);
  };
  const handleCategorieChange = (event) => {
    setCategorie(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajout(false));
    dispatch(modif(false));
    setRemoveSuccess(false);
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const suppression = async (idproduit)=> {
        setProgession(true);

        try {
            const response = await axios.post(`${apiUrl}delete/produit/${id}/${apitoken}/${idproduit}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idproduit));
                    setRemoveSuccess(true);
                    setProgession(false);
                }
        } catch (error) {
          navigate('/');
        }
    }
    
      const supprimer = (idproduit,designation) => {
        let result = window.confirm(`Supprimer le produit ${designation} ?`);
        if (result === true) {
            suppression(idproduit);
        }
    }

    const modifier = (id) => {
      dispatch(modalModifShow(true));
      const valeur = listProduits.find(produit => produit._id === id);
      dispatch(current(valeur));
  };

      function ShowList() {
        if(listProduits.length > 0){
          let listFiltre=listProduits
          if(rechercheInput.length > 2) {
            listFiltre = listFiltre.filter(produit => produit.designation.toLowerCase().includes(rechercheInput.toLowerCase()))
        }
        if(valMarque!=="Tout") {
          listFiltre = listFiltre.filter(produit => produit.marque["id"] === valMarque)
      }

      if(valCategorie!=="Tout") {
        listFiltre = listFiltre.filter(produit => produit.categorie["id"] === valCategorie)
    }
          return listFiltre.map(Produit => {
            return(
              <StyledTableRow key={Produit.designation}>
              <StyledTableCell component="th" scope="row">
                {Produit.designation}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Produit.prix_achat} Dhs
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Produit.prix_vente} Dhs
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Produit.qte_pack}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Produit.marque["designation"]}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Produit.categorie["designation"]}
              </StyledTableCell>
              <StyledTableCell align="right">
              <Button variant="outlined" color='secondary' startIcon={<EditIcon />} onClick={() => modifier(Produit._id)}>
               Modifier
              </Button>      
              <Button sx={{ marginLeft: '10px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Produit._id,Produit.designation)}>
               Supprimer
              </Button>
              </StyledTableCell>
            </StyledTableRow>
            )
            
          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={7} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }
        
          const Recherche=(event) =>{
            const recherche=rechercheField.current.value
            if(recherche!==rechercheInput){
              setRchercheInput(recherche)
               }
      
          }

      const getProduits=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}produits/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(getList(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }
    const getMarque=async () => {
      try {
      const response = await axios.post(`${apiUrl}marques/${id}/${apitoken}`,
        {
          timeout: 20000,
        }
      );
      dispatch(getListMarque(response.data));
      }
   catch (error) {
    navigate('/');    
  }
  }
  const getCategorie=async () => {
    try {
    const response = await axios.post(`${apiUrl}categories/${id}/${apitoken}`,
      {
        timeout: 20000,
      }
    );
    dispatch(getListCat(response.data));
    }
 catch (error) {
  navigate('/');    
}
}

      useEffect(() =>{
        getProduits();
        getMarque();
        getCategorie();
        dispatch(showmodal(false));
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Produits
        </Typography>

        <Box display="flex" width="100%" mt={5}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={rechercheField}
        id="input-with-icon-textfield"
        label="Recherche"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      
       }
<Box sx={{ marginLeft: 2 }}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
         <FormControl sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Marque</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={valMarque}
         label="Marque"
         onChange={handleMarqueChange}
       >
        <MenuItem value='Tout'>Tout</MenuItem>
         {listMarque.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>
}
</Box>
<Box sx={{ marginLeft: 2 }}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCategorie}
          label="Catégorie"
          onChange={handleCategorieChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {listCat.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.designation}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
}
</Box>

            <Box style={{ marginLeft: 'auto' }}>

               {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :

        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={() =>dispatch(showmodal(true))} >
    Ajouter un produit
  </Button>  
} 
</Box>
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell>Prix d'achat(H.T)</StyledTableCell>
            <StyledTableCell>Prix de vente(H.T)</StyledTableCell>
            <StyledTableCell>Qte/Pack</StyledTableCell>
            <StyledTableCell>Marque</StyledTableCell>
            <StyledTableCell>Catégorie</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
          <Ajouter/>
          <Modifier/>

          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Produit ajouté avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Produit modifié avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Produit supprimé avec succès
        </Alert>
      </Snackbar>
        </Container>
  )
}
