import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,addOne} from './chargesSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Ajouter() {
    const userData = useSelector(state => state.userData);
    const Chargedata = useSelector(state => state.chargesData);
    const showModal = Chargedata.modalShow;

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;

    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [valFrequence, setvalFrequence] = useState('Ponctuelle');
    const [valCategorie, setvalCategorie] = useState('Achats');

    const formatDateToIso = (dateString) => {
      const formattedDate = new Date(`${dateString}T00:00:00Z`);
      return formattedDate.toISOString();
    };

  const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };

    const handleFrequenceChange = (event) => {
      setvalFrequence(event.target.value);
    };
    const handleCategorieChange = (event) => {
      setvalCategorie(event.target.value);
    };

    const montantField = useRef('');
    const designationField = useRef('');
    const dateField=useRef('')

      const handleClose = () => {
        dispatch(showmodal(false));
      };
      const Ajouter =async()=>{
        let idcharge =""
        const categorie = valCategorie
        const frequence = valFrequence
        const designation=designationField.current.value.trim()
        let montant=montantField.current.value
        montant=montant.replace(",", ".")
        let date=new Date()
        date.setHours(23, 59, 59, 999);
        date=date.toISOString();
        date=date.toString()
        if(frequence ==="Ponctuelle"){
            date=formatDateToIso(dateField.current.value)
        }

        if(designation===""){
            alert("Champ désignation vide");
            return
        }
        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
          if(frequence ==="Perpétuelle"){
            const response1 = await axios.post(`${apiUrl}new/chargePerpetuelle/${id}/${apitoken}`,{
                categorie,
                montant,
                designation,
            },
            {
              timeout: 20000,
            });
            idcharge=response1.data.id    
        }
            const response = await axios.post(`${apiUrl}new/charge/${id}/${apitoken}`,
                {
                  idcharge,
                  categorie,
                  frequence,
                  montant,
                  designation,
                  date,

                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const data={_id,idcharge,categorie,frequence,montant,designation,date};
                    dispatch(showmodal(false));
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Ajouter une charge</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez remplir la fiche de la nouvelle charge
          </DialogContentText>
          <TextField sx={{ marginTop: '20px' }}
            autoFocus
            required
            margin="dense"
            id="name"
            name="designation"
            label="Désignation"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={designationField}
          />
          <Box mt={2}>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCategorie}
          label="Catégorie"
          onChange={handleCategorieChange}
        >
          <MenuItem value="Achats">Achats</MenuItem>
          <MenuItem value="Frais généreaux">Frais généreaux</MenuItem>
          <MenuItem value="Véhicules">Véhicules</MenuItem>

          </Select>
          </FormControl>
          </Box>
          <Box mt={2}>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Fréquence</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valFrequence}
          label="Fréquence"
          onChange={handleFrequenceChange}
        >
          <MenuItem value="Ponctuelle">Ponctuelle</MenuItem>
          <MenuItem value="Perpétuelle">Perpétuelle</MenuItem>

          </Select>
          </FormControl>
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="Montant"
            label="Montant H.T"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={montantField}
          />
          </Box>
          {valFrequence==="Ponctuelle" ?
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="date"
            name="date"
            label="Date"
            type="date"
            fullWidth
            variant="outlined"
            inputRef={dateField}
            value={selectedDate}
            onChange={handleDateChange}
          />
          </Box>
          :
          ''
          }
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
