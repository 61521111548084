import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./login/userSlice";
import marqueReducer from "./stock/marques/marqueSlice";
import categorieReducer from "./stock/categories/categorieSlice";
import produitsReducer from "./stock/produits/ProduitsSlice";
import fournisseursReducer from "./stock/fournisseurs/fournisseurSlice";
import achatsReducer from "./stock/achats/AchatsSlice";
import secteursReducer from "./vendeurs/secteurs/secteurSlice"
import vendeursReducer from "./vendeurs/vendeurs/vendeurSlice"
import camionReducer from "./vehicules/camions/camionSlice"
import clientReducer from "./clients/clientSlice"
import chargementsReducer from "./vendeurs/chargements/ChargementSlice"
import promosReducer from "./vendeurs/promotions/promoSlice"
import PdanewventeReducer from "./pda/nouvelleVente/venteSlice"
import BoncommandesReducer from "./vendeurs/boncommande/bonCommandeSlice"
import retoursReducer from "./stock/retourstock/RetoursSlice";
import RetoursCamionReducer from "./vendeurs/retourcamion/RetourcamionSlice";
import CloturesReducer from "./vendeurs/cloture/clotureSlice";
import VersementsReducer from "./vendeurs/versements/versementsSlice";
import ClientPDAReducer from "./pda/clients/clientSlice";
import ChequesReducer from "./tresorerie/cheques/chequeSlice";
import facturesReducer from "./facturations/factures/FactureSlice";
import chargesReducer from "./charges/charges/chargesSlice"
import commandesReducer from "./stock/commandes/commandeSlice"


export const store=configureStore({
    reducer :{
        userData : userReducer,
        marqueData : marqueReducer,
        categorieData : categorieReducer,
        produitsData : produitsReducer,
        fournisseursData : fournisseursReducer,
        achatsData : achatsReducer,
        secteursData : secteursReducer,
        vendeursData : vendeursReducer,
        camionsData : camionReducer,
        clientsData : clientReducer,
        chargementsData : chargementsReducer,
        promosData : promosReducer,
        PdanewventeData : PdanewventeReducer,
        boncommandesData : BoncommandesReducer,
        retoursData : retoursReducer,
        retourscamionData : RetoursCamionReducer,
        cloturesData : CloturesReducer,
        versementsData : VersementsReducer,
        clientsPDAData : ClientPDAReducer,
        chequesData : ChequesReducer,
        facturesData : facturesReducer,
        chargesData : chargesReducer,
        commandesData : commandesReducer,
    }
});