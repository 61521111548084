import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    modalShow : false,
    modalModifShow : false,
    list :[],
    ajoutOk : false,
    modifOk : false,
    current :[],
    listProduit : []
}
const Promos = createSlice({
    name : 'PromosData',
    initialState,
    reducers :{
        showmodal:(state,action) =>{
            state.modalShow=action.payload
        },
        getList:(state,action) =>{
            state.list=action.payload
        },
        getProdList:(state,action) =>{
            state.listProduit=action.payload
        },
        current:(state,action) =>{
            state.current=action.payload
        },
        ajout:(state,action) =>{
            state.ajoutOk=action.payload
    },
        modif:(state,action) =>{
        state.modifOk=action.payload
},
        addOne:(state,action) =>{
            state.list.push(action.payload)
    },
        modalModifShow:(state,action) =>{
            state.modalModifShow=action.payload
},
    update: (state, action) => {

        const element = state.list.find(element => element._id === action.payload.idpromo);
        if (element) {
            element.prodCible = action.payload.prodCible;
            element.qtecible = action.payload.qtecible;
            element.prodPromo = action.payload.prodPromo;
            element.qtepromo = action.payload.qtepromo;
            element.remarque = action.payload.remarque;
            element.categories = action.payload.categories;
            element.etat = action.payload.etat;
    }
  },

  changeEtat: (state, action) => {
    const element = state.list.find(element => element._id === action.payload.idpromo);
    if (element) {
        element.etat = action.payload.etat;
}
},
  
  remove: (state, action) => {    
    const id = action.payload;
    state.list=state.list.filter(element => element._id !== id);
  }

    }
})
export const {showmodal,getList,ajout,modif,addOne,modalModifShow,update,current,remove,getProdList,changeEtat} = Promos.actions
export default Promos.reducer;