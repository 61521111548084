import { Alert, Box, Button, Container, FormControl, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,getList,remove,modalModifShow,current,modif} from './chargesSlice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Ajouter from './Ajouter';
import Modifier from './Modifier';


export default function Charges() {
  const userData = useSelector(state => state.userData);
  const typeCompte = userData.type
  const [Chargement, setChargement] = useState(false);
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const chargesData=useSelector(state => state.chargesData);
  const listCharge=chargesData.list
  const ajoutSuccess = chargesData.ajoutOk
  const modifSuccess = chargesData.modifOk
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [Progession, setProgession] = useState(false);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const todayDate = `${year}-${month}-${day}`;

  const [valCategorie, setvalCategorie] = useState('Tout');
  const [valFrequence, setvalFrequence] = useState('Tout');
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [selectedDate2, setSelectedDate2] = useState(todayDate);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleDateChange2 = (event) => {
    setSelectedDate2(event.target.value);
  };
  const handleCategorieChange = (event) => {
    setvalCategorie(event.target.value);
  };
  const handleFrequenceChange = (event) => {
    setvalFrequence(event.target.value);
  };

  const dispatch= useDispatch();
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajout(false));
    dispatch(modif(false));
    setRemoveSuccess(false);
  };

  const formatDateToFrench = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const suppression = async (idcharge,frenquence,idfrequence)=> {
        setProgession(true);
        if(idfrequence==="")
          idfrequence= "0"

        try {
            const response = await axios.post(`${apiUrl}delete/charge/${id}/${apitoken}/${idcharge}/${frenquence}/${idfrequence}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idcharge));
                    setRemoveSuccess(true);
                    setProgession(false);
                }
        } catch (error) {
          navigate('/');
        }
    }
    
      const supprimer = (idcharge,designation,frenquence,idfrequence) => {
        let result = window.confirm(`Supprimer la charge ${designation} ?`);
        if (result === true) {
            suppression(idcharge,frenquence,idfrequence);
        }
    }

    const modifier = (id) => {
      dispatch(modalModifShow(true));
      const valeur = listCharge.find(item => item._id === id);
      dispatch(current(valeur));
  };

  const formatDateToEnglish = (dateString) => {
    const [date] = dateString.split('T');
    return `${date}`;
  };
      function ShowList() {
        if(listCharge.length > 0){
          let listFiltre=listCharge
          listFiltre = listFiltre.filter(charge => {
            const chargeDate = formatDateToEnglish(charge.date);
            const dateDebut = selectedDate
            const dateFin = selectedDate2
            return chargeDate >= dateDebut && chargeDate <= dateFin;
          });
          if (valCategorie !=="Tout"){
            listFiltre = listFiltre.filter(charge => charge.categorie===valCategorie )
            ;}
            if (valFrequence !=="Tout"){
              listFiltre = listFiltre.filter(charge => charge.frequence===valFrequence )
              ;}
          return listFiltre.map(Item => {
            return(
              <StyledTableRow key={Item._id}>
              <StyledTableCell component="th" scope="row">
                {Item.designation}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Item.montant} Dhs
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Item.categorie}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Item.frequence}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Item.date!=="" ? formatDateToFrench(Item.date) : ''}
              </StyledTableCell>
              <StyledTableCell align="right">
              { typeCompte === "admin" ?
              <>
              <Button variant="outlined" color='secondary' startIcon={<EditIcon />} onClick={() => modifier(Item._id)}>
               Modifier
              </Button>      
              <Button sx={{ marginLeft: '10px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Item._id,Item.designation,Item.frequence,Item.idcharge)}>
               Supprimer
              </Button>
              </>
                :
                ""
                }
              </StyledTableCell>
            </StyledTableRow>
            )

          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={6} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

      const getCharges=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}charges/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(getList(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

      useEffect(() =>{
        getCharges();
        dispatch(showmodal(false));
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Charges
        </Typography>

        <Box display="flex" width="100%" mt={5}>
          <Box mt={3} width={170}>
        {Chargement ? 
        
        <Skeleton  variant="rounded" height={56} fullWidth />
         :
         <FormControl fullWidth>
         <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
         <Select
       
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={valCategorie}
         label="Catégorie"
         onChange={handleCategorieChange}
       >
        <MenuItem value="Tout">Tout</MenuItem>
         <MenuItem value="Achats">Achats</MenuItem>
         <MenuItem value="Frais généreaux">Frais généreaux</MenuItem>
         <MenuItem value="Véhicules">Véhicules</MenuItem>

         </Select>
         </FormControl>

       }
       </Box>

       <Box mt={3} ml={2} width={170}>
        {Chargement ? 
        
        <Skeleton  variant="rounded" height={56} fullWidth />
         :
         <FormControl fullWidth>
         <InputLabel id="demo-simple-select-label">Fréquence</InputLabel>
         <Select
       
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={valFrequence}
         label="Fréquence"
         onChange={handleFrequenceChange}
       >
        <MenuItem value="Tout">Tout</MenuItem>
        <MenuItem value="Ponctuelle">Ponctuelle</MenuItem>
        <MenuItem value="Perpétuelle">Perpétuelle</MenuItem>
         </Select>
         </FormControl>

       }
       </Box>


                 <Box mt={2} ml={2} width={160}>
                 {Chargement ? 
        <Skeleton  variant="rounded" height={56}  />
         :
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Du"
            type="date"
            fullWidth
            variant="outlined"
            value={selectedDate}
            onChange={handleDateChange}
          />

            }
          </Box>

          <Box mt={2} ml={2} width={160}>
                 {Chargement ? 
        <Skeleton  variant="rounded" height={56}  />
         :
          <TextField
            autoFocus
            margin="dense"
            id="date1"
            name="date"
            label="Au"
            type="date"
            fullWidth
            variant="outlined"
            value={selectedDate2}
            onChange={handleDateChange2}
          />

            }
          </Box>
          <Box mt={2} style={{ marginLeft: 'auto' }}>
                {Chargement ? 
        <Skeleton  variant="rounded" height={56} width={241} />
         :
        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={() =>dispatch(showmodal(true))} >
    Ajouter une charge
  </Button>   
    }
    </Box>
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell>Montant H.T</StyledTableCell>
            <StyledTableCell>Catégorie</StyledTableCell>
            <StyledTableCell>N° Fréquence</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
          <Ajouter/>
          <Modifier/>

          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Charge ajoutée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Charge modifiée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Charge supprimée avec succès
        </Alert>
      </Snackbar>
        </Container>
  )
}
