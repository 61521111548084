import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current } from './secteurSlice';
import {Box, LinearProgress, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Modifier() {
    const userData = useSelector(state => state.userData);
    const secteurData = useSelector(state => state.secteursData);
    const showModal = secteurData.modalModifShow;
    const currentelement = secteurData.current;

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const designationField = useRef('')
    const telField = useRef('')
    const compteField = useRef('')
    const adresseField = useRef('')
    const emailField = useRef('')

    const ChampUpdate = () =>{
      const _id=currentelement._id;
      const designation=designationField.current.value;
      const data={_id,designation};
      dispatch(current(data)); 
    }

      const handleClose = () => {
        dispatch(modalModifShow(false));
      };
      const Ajouter =async()=>{
        const designation =designationField.current.value.trim()
        const idsecteur = currentelement._id;
        if(designation===""){
            alert("Champ désignation vide");
            return
        }
        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}edit/secteur/${id}/${apitoken}/${idsecteur}`,
                {
                  designation            
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const data={idsecteur,designation};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));
                    
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Modifier secteur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Entrez la Désignation du secteur.
          </DialogContentText>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="designation"
            label="Désignation"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={designationField}
            value={currentelement.designation}
            onChange={ChampUpdate}
          />
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
