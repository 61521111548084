import { Box, Button, Container, InputAdornment, LinearProgress, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export default function Stock() {
    const [listProduits, setlistProduits] = useState([]);
    const userData = useSelector(state => state.userData);
    const [Chargement, setChargement] = useState(false);
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const navigate = useNavigate();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const GetPack =(nb_unite,qte_pack) => Math.floor(nb_unite / qte_pack)
      const GetUnite =(nb_unite,qte_pack) => nb_unite % qte_pack

      function ShowList() {
        if(listProduits.length > 0){
          return listProduits.map(Produit => {
            return(
              <StyledTableRow key={Produit.designation}>
              <StyledTableCell component="th" scope="row">
              {Produit.designation}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {GetPack(Produit.stock,Produit.qte_pack)}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {GetUnite(Produit.stock,Produit.qte_pack)}
              </StyledTableCell>
            </StyledTableRow>
            )
            
          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={3} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

          const getProduits=async () => {
            setChargement(true);
            try {
            const response = await axios.post(`${apiUrl}produits/${id}/${apitoken}`,
              {
                timeout: 20000,
              }
            );
            setlistProduits(response.data);
            }
         catch (error) {
          navigate('/');    
        }
        setChargement(false);
        }         

        useEffect(() =>{
            getProduits();
            },[]);

  return (
<Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Stock
        </Typography>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell>Quantité des Packs</StyledTableCell>
            <StyledTableCell>Quantité des Unitées</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
      }
</Container>
  )
}
