import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    modalShow : false,
    modifOk : false,
    id : '',
    nom : '',
    type : ''

}
const userSlice = createSlice({
    name : 'userData',
    initialState,
    reducers :{
        creation:(state,action) =>{
            state.id=action.payload._id
            state.nom=action.payload.nom
            state.type=action.payload.type
            state.apitoken=action.payload.apitoken
            state.secteurs=action.payload.secteurs
        },
        showpasswordmodal:(state,action) =>{
            state.modalShow=action.payload
        },
        modifpassword:(state,action) =>{
            state.modifOk=action.payload
    },
    }

})

export const {creation,showpasswordmodal,modifpassword} = userSlice.actions
export default userSlice.reducer;