import { Alert, Box, Button, Container, FormControl, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';

import { ajout,getvendeurs,showmodal,getList,remove } from "./clotureSlice";
import axios from 'axios';
import Ajouter from './Ajouter';

export default function Cloturer() {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      
    const [Chargement, setChargement] = useState(false);
    const [Progession, setProgession] = useState(false);
    const [valVendeurs, setvalVendeurs] = useState('Tout');
    const [removeSuccess, setRemoveSuccess] = useState(false);

    const ClotureData = useSelector(state => state.cloturesData);
    const ListVendeur = ClotureData.listVendeurs;
    const listClotures=ClotureData.list;
    const ajoutSuccess = ClotureData.ajoutOk;


    const handleVendeurChange = (event) => {
        setvalVendeurs(event.target.value);
      };

      const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      };

      const navigate = useNavigate();
      const dispatch= useDispatch();

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        dispatch(ajout(false));
        setRemoveSuccess(false);
      };

    const userData = useSelector(state => state.userData)
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;

      const suppression = async (idcloture)=> {
        setProgession(true);

        try {
            const response = await axios.post(`${apiUrl}delete/cloture/${id}/${apitoken}/${idcloture}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idcloture));
                    setRemoveSuccess(true);
                    setProgession(false);
                }
        } catch (error) {
          navigate('/');
        }
    }
      const supprimer = (id,nom) => {
        let result = window.confirm(`Supprimer cette clôture de ${nom} ?`);
        if (result === true) {
            suppression(id);
        }
      }

    function ShowList() {
        if(listClotures.length > 0){
          let listFiltre=listClotures
        
          if(valVendeurs!=="Tout") {
            listFiltre = listFiltre.filter(cloture => cloture.vendeur["id"] === valVendeurs)
        }
          return listFiltre.map(Clotures => {
            return(
              <StyledTableRow key={Clotures._id}>
              <StyledTableCell component="th" scope="row">
                {Clotures.vendeur.nom}
              </StyledTableCell>
      
              <StyledTableCell component="th" scope="row">
              {Clotures.date!=="" ? formatDateToFrench(Clotures.date) : ''}
              </StyledTableCell>
              <StyledTableCell align="right">
              <Button sx={{ marginLeft: '10px',width:'140px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Clotures._id,Clotures.vendeur.nom)}>
               Supprimer
              </Button>
              </StyledTableCell>
            </StyledTableRow>
            )
      
          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={7} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

    const getVendeurs=async () => {
        try {
        const response = await axios.post(`${apiUrl}vendeurs/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(getvendeurs(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    }

      const getBons=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}clotures/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(getList(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

    useEffect(() =>{
        getBons();
        getVendeurs();
        dispatch(showmodal(false));
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
        
        <Typography variant="h4" align='center' color="primary">
        Liste des clôtures
        </Typography>
        <Box display="flex" width="100%" mt={5}>
        <Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Vendeurs</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valVendeurs}
          label="Vendeurs"
          onChange={handleVendeurChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {ListVendeur.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.nom}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
}
</Box>

            <Box style={{ marginLeft: 'auto' }}>

               {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :

        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={() =>dispatch(showmodal(true))} >
    Nouvelle clôture
  </Button>  
} 
</Box>

            </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Vendeur</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Clôture ajoutée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Clôture supprimée avec succès
        </Alert>
      </Snackbar>
    </>
}
<Ajouter/>
    </Container>
  )
}
