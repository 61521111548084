import { useSelector } from "react-redux";
import AdminStats from "./admin/AdminStats"
import VendeurStats from "./vendeur/VendeurStats";

export default function Dash() {
    const userData = useSelector(state => state.userData);
    const typeCompte = userData.type
  return (
    <>
        {
     typeCompte === "admin" ?
     <AdminStats/>
     :
     ''
    }
            {
     typeCompte === "vendeur" ?
     <VendeurStats/>
     :
     ''
    }
    </>
  )
}
