import { Alert, Box, Button, Container, InputAdornment, LinearProgress, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import Ajouter from './Ajouter';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,getList,remove,modalModifShow,current,modif} from './marqueSlice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modifier from './Modifier';

import SearchIcon from '@mui/icons-material/Search';


export default function Marques() {
  const userData = useSelector(state => state.userData);
  const [Chargement, setChargement] = useState(false);
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const marqueData=useSelector(state => state.marqueData);
  const listMarques=marqueData.list
  const ajoutSuccess = marqueData.ajoutOk
  const modifSuccess = marqueData.modifOk
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [Progession, setProgession] = useState(false);
  const [rechercheInput,setRchercheInput] = useState('');

  const rechercheField = useRef('')

  const dispatch= useDispatch();
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajout(false));
    dispatch(modif(false));
    setRemoveSuccess(false);
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const suppression = async (idmarque)=> {
        setProgession(true);

        try {
            const response = await axios.post(`${apiUrl}delete/marque/${id}/${apitoken}/${idmarque}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idmarque));
                    setRemoveSuccess(true);
                    setProgession(false);
                }
        } catch (error) {
          navigate('/');
        }
    }
    
      const supprimer = (idmarque,designation) => {
        let result = window.confirm(`Supprimer la marque ${designation} ?`);
        if (result === true) {
            suppression(idmarque);
        }
    }

    const modifier = (id) => {
      dispatch(modalModifShow(true));
      const valeur = listMarques.find(client => client._id === id);
      dispatch(current(valeur));
  };

      function ShowList() {
        if(listMarques.length > 0){
          let listFiltre=listMarques
          if(rechercheInput.length > 2) {
            listFiltre = listFiltre.filter(marque => marque.designation.toLowerCase().includes(rechercheInput.toLowerCase()))
        }
          return listFiltre.map(Marque => {
            return(
              <StyledTableRow key={Marque.designation}>
              <StyledTableCell component="th" scope="row">
                {Marque.designation}
              </StyledTableCell>
              <StyledTableCell align="right">
              <Button variant="outlined" color='secondary' startIcon={<EditIcon />} onClick={() => modifier(Marque._id)}>
               Modifier
              </Button>      
              <Button sx={{ marginLeft: '10px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Marque._id,Marque.designation)}>
               Supprimer
              </Button>
              </StyledTableCell>
            </StyledTableRow>
            )

          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={2} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }
        
          const Recherche=(event) =>{
            const recherche=rechercheField.current.value
            if(recherche!==rechercheInput){
              setRchercheInput(recherche)
               }
      
          }

      const getMarque=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}marques/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(getList(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

      useEffect(() =>{
        getMarque();
        dispatch(showmodal(false));
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Marques
        </Typography>

        <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={rechercheField}
        id="input-with-icon-textfield"
        label="Recherche"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
        }
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :
        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={() =>dispatch(showmodal(true))} >
    Ajouter une marque
  </Button> 
}  
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
          <Ajouter/>
          <Modifier/>

          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Marque ajoutée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Marque modifiée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Marque supprimée avec succès
        </Alert>
      </Snackbar>
        </Container>
  )
}
