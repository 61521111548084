import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update} from './chequeSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Ajouter() {
    const userData = useSelector(state => state.userData);
    const Chequedata = useSelector(state => state.chequesData);
    const currentelement = Chequedata.current;
    const listClients = Chequedata.listClients;    
    const showModal = Chequedata.modalModifShow;
    const [ValClient, setValClient] = useState('');
    const [valEtat, setvalEtat] = useState('');

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const numeroField=useRef('')
    const dateField=useRef('')
    const montantField=useRef('')
    const remarqueField = useRef('')

    const handleClientChange = (event) => {
      setValClient(event.target.value);
    };
    const handleEtatChange = (event) => {
      setvalEtat(event.target.value);
    };
      const handleClose = () => {
        dispatch(modalModifShow(false));
      };
      const Ajouter =async()=>{
        const numero=numeroField.current.value.trim()
        const date=formatDateToIso(dateField.current.value)
        const etat=valEtat
        let montant=montantField.current.value
        montant=montant.replace(",", ".")

        const remarque=remarqueField.current.value

        const valeur = listClients.find(item => item._id === ValClient);

        const client = {
          id: valeur._id,
          nom: valeur.nom,
          prenom: valeur.prenom,
          client_code : valeur.client_code,
        };

        if(montant===""){
          alert("Champ montant vide");
          return
      }

        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        const idcheque = currentelement._id;
        try {
            const response = await axios.post(`${apiUrl}edit/cheque/${id}/${apitoken}/${idcheque}`,
                {
                  client,
                  numero,
                  montant,
                  remarque,
                  etat,
                  date,
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                  const data={idcheque,client,numero,montant,remarque,etat,date};
                  dispatch(modalModifShow(false));
                  dispatch(modif(true));
                  dispatch(update(data));
              }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

    const formatDateToFrench = (dateString) => {
      try {
      const [date] = dateString.split('T');
      const [year, month, day] = date.split('-');
      return `${year}-${month}-${day}`;
      }
      catch (error) {
        return ""    
      }
    };

    const [selectedDate, setSelectedDate] = useState('');

    const formatDateToIso = (dateString) => {
      const formattedDate = new Date(`${dateString}T00:00:00Z`);
      return formattedDate.toISOString();
    };

  const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };

    useEffect(() =>{
      if (Object.keys(currentelement).length > 0) {
      setValClient(currentelement.client?.id)
      setvalEtat(currentelement.etat)
      setSelectedDate(formatDateToFrench(currentelement.date))
      }
      },[showModal]);

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Modifier chèque</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez modifier la fiche du chèque
          </DialogContentText>
          <Box mt={2}>

         <FormControl fullWidth sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Clients</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         fullWidth
         value={ValClient}
         label="Clients"
         onChange={handleClientChange}
       >
         {listClients.map((item) => (
         <MenuItem key={item._id} value={item._id}>
          
           {item?.nom} {item?.prenom} / {item?.client_code} / {item.secteur?.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>

</Box>
<Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="numero"
            name="numero"
            label="Numéro de chèque"
            type="text"
            fullWidth
            variant="outlined"
            defaultValue={currentelement.numero}
            inputRef={numeroField}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="montant"
            name="mantant"
            label="Montant"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={montantField}
            defaultValue={currentelement.montant}
          />
          </Box>
          <Box mt={2}>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Etat</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valEtat}
          label="Etat"
          onChange={handleEtatChange}
        >
          <MenuItem value="Enregistré">Enregistré</MenuItem>
          <MenuItem value="En attente d'encaissement">En attente d'encaissement</MenuItem>
          <MenuItem value="Encaissé">Encaissé</MenuItem>

          </Select>
          </FormControl>
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="date"
            name="date"
            label="Date d'encaissement"
            type="date"
            fullWidth
            variant="outlined"
            inputRef={dateField}
            value={selectedDate}
            onChange={handleDateChange}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="remarque"
            name="remarque"
            label="Remarque"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={remarqueField}
            defaultValue={currentelement.remarque}
          />
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
