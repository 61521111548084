import { Alert, Box, Button, Container, FormControl, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import Apercu from './Apercu';
import { useDispatch } from 'react-redux';
import { ajoutsuccess,showapercu } from "./commandeSlice";
export default function NouvelleCommande() {
    const userData = useSelector(state => state.userData);
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;

    const commandesData = useSelector(state => state.commandesData);
    const ListFournisseur = commandesData.listFournisseurs;

    const dispatch= useDispatch();

    const handleFournisseurChange = (event) => {
        setvalFournisseur(event.target.value);
      };
    const [Progession, setProgession] = useState(false);
    const [valFournisseur, setvalFournisseur] = useState('');
    const [Chargement, setChargement] = useState(false);
    const [rechercheInput,setRchercheInput] = useState('');
    const [valMarque, setMarque] = useState('Tout');
    const [valCategorie, setCategorie] = useState('Tout');
    const [listMarque,setListMarque] = useState([]);
    const [listCat,setListCat] = useState([]);
    const [listProduits, setListProduits] = useState([]);
  
    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
  

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;
  
    const [selectedDate, setSelectedDate] = useState(todayDate);

    const navigate = useNavigate();

    const fournisseurField = useRef('')
    const rechercheField = useRef('')
    let listAchats=useRef([]);
  
    const formatDateToIso = (dateString) => {
      const formattedDate = new Date(`${dateString}T00:00:00Z`);
      formattedDate.setHours(23, 59, 59, 999);
      return formattedDate.toISOString();
      };

      const removeZeroStock = (list) => {
        return list.current.filter(item => item.stock !== "0");
      };
    const ShowApercu =() =>{
        dispatch(showapercu(true))
   }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


  const Recherche=(event) =>{
    const recherche=rechercheField.current.value
    if(recherche!==rechercheInput){
      setRchercheInput(recherche)
       }
  }

  const handleMarqueChange = (event) => {
    setMarque(event.target.value);
  };

  const handleCategorieChange = (event) => {
    setCategorie(event.target.value);
  }

  const calculateSum = (products) => {
    return products.reduce((total, product) => {
        if(product.stock === 0) {
            product.stock = 0
        }
        const tvaAmount = product.prix_achat * 0.2;
        return total + ((product.prix_achat + tvaAmount) * product.unite);
    
    }, 0);
};

    const getMarque=async () => {
        try {
        const response = await axios.post(`${apiUrl}marques/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListMarque(response.data);
        }
     catch (error) {
      navigate('/');    
    }
    }
    const getCategorie=async () => {
        try {
        const response = await axios.post(`${apiUrl}categories/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListCat(response.data);
        }
     catch (error) {
      navigate('/');    
    }
    }

    const getProduits=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}produits/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListProduits(response.data);
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

    const formatDateToFrench = (dateString) => {
      const [date] = dateString.split('T');
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    };

    const StockUpdate = (event) => {
        const qte = event.target.value;

        const id = event.target.id;
        const updatedListProduits = listProduits.map(product => {
            if (product._id === id) {
                product.stock = qte
                const _id=product._id
                const designation=product.designation
                const stock=product.stock
                const prix_achat=product.prix_achat
                const unite = product.stock * product.qte_pack
                const valeurs={_id,designation,stock,unite,prix_achat}
                // Vérifier si l'_id existe déjà dans le tableau
                const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
                if (existingProductIndex !== -1) {
                    // Mettre à jour la valeur du stock et les unités
                    listAchats.current[existingProductIndex].stock = stock;
                    listAchats.current[existingProductIndex].unite = unite;
                  } else {
                    // Ajouter le nouveau produit au tableau
                    listAchats.current.push({...valeurs});
                  }
                return { listAchats };
            }
            return product;
        });
        
    };

    function ShowList() {
        if(listProduits.length > 0){
            let listFiltre=listProduits
            if(rechercheInput.length > 2) {
                listFiltre = listFiltre.filter(produit => produit.designation.toLowerCase().includes(rechercheInput.toLowerCase()))
            }
            if(valMarque!=="Tout") {
                listFiltre = listFiltre.filter(produit => produit.marque["id"] === valMarque)
            }
      
            if(valCategorie!=="Tout") {
              listFiltre = listFiltre.filter(produit => produit.categorie["id"] === valCategorie)
          }
        return listFiltre.map(Produit => {
            const currentQte = listAchats.current.find(item => item._id === Produit._id);
            let stock="0";
            if(currentQte){
                stock=currentQte.stock;
            }

            return(
            
            <StyledTableRow key={Produit._id}>
            <StyledTableCell component="th" scope="row">
              {Produit.designation}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            <TextField
        onChange={StockUpdate}
        defaultValue={stock}
        id={Produit._id}
        label="Packs"
        size='normal'
        type='number'
        variant="outlined"
      />
            </StyledTableCell>
            </StyledTableRow>
            )
            
        });
        
    }
    else{
        return <tr>
            <td colSpan={6} align='center'>Pas de données</td>
        </tr>;
    }
    
    }    

    const GetCommande = async (idcommande,date) =>{
      try {
          const dateFr=formatDateToFrench(date)
          const response = await axios.get(`${apiUrl}generer/commandesfournisseur/${id}/${apitoken}/${idcommande}`,{
            responseType: 'blob',
          });
          // Créez un lien pour télécharger le PDF
          const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',`Bon de commande du ${dateFr}.pdf`);
          document.body.appendChild(link);
          link.click();
      }
          catch (error) {
              console.error('Error generating fsortie:', error);
            }
  }

    const Ajouter = async () => {

      const idfournisseur=fournisseurField.current.value
      if(idfournisseur==="")
      {
        alert("veillez sélectionner un fournisseur")
        return
      }
      let fournisseur = ListFournisseur.find(item => item._id === idfournisseur);
      fournisseur = fournisseur.designation
      fournisseur={
          "id" : idfournisseur,
          "designation" : fournisseur,
      };

      const date = formatDateToIso(selectedDate)
      const produits = removeZeroStock(listAchats)
      let total=calculateSum(listAchats.current)
      total = total.toFixed(2)
      setProgession(true);
      try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}new/commandefournisseur/${id}/${apitoken}`,
        {
              fournisseur,
              date,
              produits,
              total,
          },
          {
            timeout: 20000,
          }
        );
          GetCommande(response.data.id,date)
          dispatch(ajoutsuccess(true));
          navigate('/home/commandesfournisseur', { replace: true });
      }
   catch (error) {
    navigate('/'); 
  }
  setProgession(false);
  }    

    useEffect(() =>{
        getProduits();
        getMarque();
        getCategorie();
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Nouvelle commande
        </Typography>

        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} />
         :
          
          <Box display="flex" width="100%" mt={5}>

              <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Fournisseur</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id={valFournisseur}
          inputRef={fournisseurField}
          value={valFournisseur}
          label="Fournisseur"
          onChange={handleFournisseurChange}
        >
          {ListFournisseur.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
                      


              <TextField sx={{ marginLeft: 2 }}
            value={selectedDate}
            onChange={handleDateChange}
            id="input-with-icon-textfield"
            label="Date"
            size='normal'
            type='date'
        variant="outlined"
      />                          
</Box> 
}
    <Typography variant="h4" align='center' color="primary" sx={{ marginTop: '40px' }}>
        Produits
        </Typography> 

        <Box display="flex" width="100%" mt={5}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={rechercheField}
        id="input-with-icon-textfield"
        label="Recherche"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      
       }
<Box sx={{ marginLeft: 2 }}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
         <FormControl sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Marque</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={valMarque}
         label="Marque"
         onChange={handleMarqueChange}
       >
        <MenuItem value='Tout'>Tout</MenuItem>
         {listMarque.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>
}
</Box>
<Box sx={{ marginLeft: 2 }}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCategorie}
          label="Catégorie"
          onChange={handleCategorieChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {listCat.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.designation}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
}
</Box>
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell>Quantité</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
<Box display="flex" width="100%" mt={2}>
<Box style={{ marginLeft: 'auto' }}>

{Chargement ? 
<Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
:

<Button sx={{ marginRight: '20px' }} variant="contained" size='large' color="primary" startIcon={<RemoveRedEyeOutlinedIcon/>} onClick={ShowApercu} >
Aperçu
</Button>  
} 
{Chargement ? 
<Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
:

<Button variant="contained" disabled={Progession} size='large' color="success" startIcon={<CloudDoneOutlinedIcon/>} onClick={Ajouter} >
Enregistrer
</Button>  
} 
</Box>
</Box>
<Apercu Achats={listAchats} />
    </Container>
  )
}
