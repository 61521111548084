import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current } from './vendeurSlice';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import axios from 'axios';
import { FormControlLabel, FormGroup, LinearProgress, Stack, Switch } from '@mui/material';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Ajouter() {


    const userData = useSelector(state => state.userData);
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;
    const VendeursData = useSelector(state => state.vendeursData);
    const showModal = VendeursData.modalModifShow;
    const currentelement = VendeursData.current;

    const navigate = useNavigate();
    const [checked, setChecked] = useState(true);

    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const theme = useTheme();
    const [personName, setPersonName] = useState([]);
    const [Listsecteurs, setListsecteurs] = useState([]);

    const handleCheckChange = (event) => {
      setChecked(event.target.checked);
    };

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const designationField = useRef('')
    const telField = useRef('')
    const secteursField = useRef('')

    const listSecteursBrut = useRef([])

      const handleClose = () => {
        dispatch(modalModifShow(false));
      };

      const getSecteur=async () => {
        if(Object.keys(currentelement).length > 0){
        setPersonName(currentelement.secteurs.map(item => item.designation))
      }
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}secteurs/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        listSecteursBrut.current = response.data
        setListsecteurs(response.data.map(item => item.designation))
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }
      const Ajouter =async()=>{
        const idvendeur = currentelement._id;
        const nom =designationField.current.value.trim()
        const tel =telField.current.value.trim()
        if(nom===""){
            alert("Champ désignation vide");
            return
        }
        if(tel===""){
          alert("Champ N° Tel. vide");
          return
      }
        const tempSecteurs = secteursField.current.value

        const secteurs = tempSecteurs.map(designation => {
          const match = listSecteursBrut.current.find(item => item.designation === designation);
          if (match) {
            return {
              id: match._id,
              designation: match.designation
            };
          }
          return null;
        }).filter(item => item !== null);
        setChargement(true)
        const salaire = 0
        const forceTotp = checked ? "Oui" : "Non";
        const type = 'vendeur'
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}edit/vendeur/${id}/${apitoken}/${idvendeur}`,
                {
                    nom,
                    tel,
                    type,
                    salaire,
                    forceTotp,
                    secteurs
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const data={idvendeur,nom,tel,type,salaire,forceTotp,secteurs};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));  
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

    useEffect(() =>{
      getSecteur();
      setChecked(currentelement.forceTotp==="Oui" ? true : false)
      },[showModal]);

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Modifier un Vendeur</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez modifier la fiche du vendeur
          </DialogContentText>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="designation"
            label="Désignation"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={designationField}
            defaultValue={currentelement.nom}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="tel"
            label="N° Tel."
            type="text"
            fullWidth
            variant="outlined"
            inputRef={telField}
            defaultValue={currentelement.tel}
          />
          </Box>
          <Box mt={2}>
          <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label">Secteurs</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          inputRef={secteursField}
          input={<OutlinedInput id="select-multiple-chip" label="Secteurs" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {Listsecteurs?.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          </Box>
          <Box mt={2} display="flex" fullWidth alignItems="left" justifyContent="left">
          <FormGroup>
          <FormControlLabel labelPlacement="start" control={<Switch checked={checked} onChange={handleCheckChange} />} label="Protection 2FA" />
          </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}