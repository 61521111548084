import { Alert, Box, Button, Container, FormControl, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { useSelector,useDispatch } from 'react-redux';
import { ajoutsuccess,currentelement,modifsuccess,showapercu,listclients,showmodel,govierge, goventes } from "./FactureSlice";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import SearchIcon from '@mui/icons-material/Search';
import Apercu from './Apercu';
import Model from './Model';

export default function Factures() {
  const userData = useSelector(state => state.userData);
  const [Chargement, setChargement] = useState(false);
  const id = userData.id;
  const typeCompte = userData.type
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const facturesData=useSelector(state => state.facturesData);
  const ajoutSuccess = facturesData.ajoutsuccess
  const modifSuccess = facturesData.modifsuccess
  const Govierge = facturesData.GoVierge
  const Goventes = facturesData.GoVentes
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [Progession, setProgession] = useState(false);
  const [rechercheNom,setRchercheNom] = useState('');
  const [recherchePrenom,setrecherchePrenom] = useState('');
  const [rechercheNumero,setrechercheNumero] = useState('');
  const [listFactures, setlistFactures] = useState([]);

  const rechercheNomField = useRef('')
  const recherchePrenomField = useRef('')
  const rechercheFactureField = useRef('')

  const Total = useRef(0)
  const listProduits = useRef([])


  const dispatch= useDispatch();
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajoutsuccess(false));
    dispatch(modifsuccess(false));
    setRemoveSuccess(false);
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      };

      const suppression = async (idfacture)=> {
        setProgession(true);

        try {
            const response = await axios.post(`${apiUrl}delete/facture/${id}/${apitoken}/${idfacture}`);
            if(response.data.status==="ok")
                {
                  const newlist=listFactures.filter(item => item._id !== idfacture);
                    setlistFactures(newlist);
                    setRemoveSuccess(true);
                    setProgession(false);
                }
        } catch (error) {
          navigate('/');
        }
    }
    
      const supprimer = (idfacture,facture) => {
        let result = window.confirm(`Supprimer la facture N° ${facture} ?`);
        if (result === true) {
            suppression(idfacture);
        }
    }

    const Ajouter=() => {
      // navigate('/home/nouvellefacture');
      dispatch(showmodel(true))
      }

      const GetFacture = async (idfacture,date,facture) =>{
        try {
            const dateFr=formatDateToFrench(date)
            const response = await axios.get(`${apiUrl}generer/facture/${id}/${apitoken}/${idfacture}`,{
              responseType: 'blob',
            });
            // Créez un lien pour télécharger le PDF
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',`Facture N° ${facture} du ${dateFr}.pdf`);
            document.body.appendChild(link);
            link.click();
        }
            catch (error) {
                console.error('Error generating fsortie:', error);
              }
    }
    
      const ShowApercu =(produits,total) => {
        Total.current = total
        listProduits.current = produits
        dispatch(showapercu(true))
      }

      const modifier = (id) => {
        const filtre = listFactures.filter(item => item._id === id);
        dispatch(currentelement(filtre));
        navigate('/home/editfacture');
      }

      function ShowList() {
        if(listFactures.length > 0){
          let listFiltre=listFactures
          if(rechercheNom.length > 2) {
            listFiltre = listFiltre.filter(item => item.client.nom.toLowerCase().includes(rechercheNom.toLowerCase()))
        }
        if(recherchePrenom.length > 2) {
          listFiltre = listFiltre.filter(item => item.client.prenom.toLowerCase().includes(recherchePrenom.toLowerCase()))
      }
      if(rechercheNumero.length > 0) {
        listFiltre = listFiltre.filter(item => item.facture===rechercheNumero)
    }
          return listFiltre.map(Item => {
            return(
              <StyledTableRow key={Item._id}>
              <StyledTableCell component="th" scope="row">
              {Item.client.nom} {Item.client.prenom} / {Item.client.client_code}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Item.facture}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Item.total.toFixed(2)} Dhs
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Item.reglement}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {Item.date!=="" ? formatDateToFrench(Item.date) : ''}
              </StyledTableCell>
              <StyledTableCell align="right">
          <Box>
        <Button sx={{ width:'140px' }} variant="outlined" color='secondary' startIcon={<VisibilityIcon />} onClick={() => ShowApercu(Item.produits,Item.total)}>
        Aperçu
        </Button> 
        <Button sx={{ marginLeft: '10px',width:'140px' }} variant="outlined" color='primary' startIcon={<PrintIcon />} onClick={() => GetFacture(Item._id,Item.date,Item.facture)}>
        Imprimer
        </Button> 
        </Box>
        { typeCompte === "admin" ? 
        <Box mt={1}>
          { Item.type === "vierge" ? 
        <Button sx={{ width:'140px' }} variant="outlined" color='warning' startIcon={<EditIcon />} onClick={() => modifier(Item._id)}>
         Modifier
        </Button>  
        :
        ''
          }    
        <Button sx={{ marginLeft: '10px',width:'140px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Item._id,Item.facture)}>
         Supprimer
        </Button>
        </Box>
        : 
        ''
    }
        </StyledTableCell>
            </StyledTableRow>
            )

          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={6} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

      const getFactures=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}factures/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setlistFactures(response.data)
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

      const getClients=async () => {
        try {
        const response = await axios.post(`${apiUrl}clients/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(listclients(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    }
    const Recherche=(event) =>{
      const nom=rechercheNomField.current.value.trim()
      const prenom=recherchePrenomField.current.value.trim()
      const facture = rechercheFactureField.current.value.trim()
      if(nom!==rechercheNom){
        setRchercheNom(nom)
         }
         if(prenom!==recherchePrenom){
          setrecherchePrenom(prenom)
           }
           if(facture!==rechercheNumero){
            setrechercheNumero(facture)
             }
    }

      useEffect(() =>{
        getClients();
        getFactures()
        },[]);

        useEffect(() =>{
          if(Govierge===true){
            navigate('/home/nouvellefacture');
            dispatch(govierge(false))
          }
          },[Govierge]);

          useEffect(() =>{
            if(Goventes===true){
              navigate('/home/factureventes');
              dispatch(goventes(false))
            }
            },[Goventes]);
  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Factures
        </Typography>

        <Box display="flex" width="100%" mt={5}>
          <Box mt={2}>
        {Chargement ? 
        <Skeleton  variant="rounded" height={56} width={241} />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={rechercheNomField}
        id="input-with-icon-textfield"
        label="Recherche Nom"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
       }
       </Box>
             <Box mt={2} sx={{ marginLeft: 2 }}>
             {Chargement ? 
        <Skeleton  variant="rounded" height={56} width={241} />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={recherchePrenomField}
        id="input-with-icon-textfield2"
        label="Recherche Prénom"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
       }
              
             </Box>

             <Box mt={2} sx={{ marginLeft: 2 }}>
             {Chargement ? 
        <Skeleton  variant="rounded" height={56} width={241} />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={rechercheFactureField}
        id="input-with-icon-textfield2"
        label="N° Facture"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
       }
              
             </Box>
       <Box style={{ marginLeft: 'auto' }}>
                {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :
        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={Ajouter}>
        Nouvelle facture
        </Button>   
    }
    </Box>
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
          <StyledTableCell>Client</StyledTableCell>
            <StyledTableCell>N° Facture</StyledTableCell>
            <StyledTableCell>Total TTC</StyledTableCell>
            <StyledTableCell>Mode réglement</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Facture ajoutée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Facture modifiée avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Facture supprimée avec succès
        </Alert>
      </Snackbar>
      <Apercu Chargements={listProduits} Total={Total.current} />
      <Model/>
        </Container>
  )
}
