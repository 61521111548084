import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector,useDispatch } from 'react-redux';
import { showapercu } from "./FactureSlice";

export default function Apercu({Chargements,Total}) {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
    
    const facturesData = useSelector(state => state.facturesData);
    const modalShow = facturesData.apercuShow;

    const dispatch= useDispatch();
    const handleClose = () => {
        dispatch(showapercu(false));   
      };

      function ShowList() {

        const listFiltre = Chargements?.current.filter(chargement => chargement.pack>0 || chargement.unite >0 )
        return(
            listFiltre.map(chargement =>{
                return(

                    <StyledTableRow key={chargement._id}>
                    <StyledTableCell component="th" scope="row">
                    {chargement.designation}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                    {chargement.pack}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                    {chargement.unite}
                    </StyledTableCell>
                    </StyledTableRow>
                )

            })
        )
    }

  return (
    <Dialog
                open={modalShow}
                onClose={handleClose}
                >
    <DialogTitle align='center'>Aperçu de la facture</DialogTitle>
    <DialogContent>
    <DialogContentText align='center'>
          Liste des produits
          </DialogContentText>
          <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table sx={{ minWidth: 400 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell>Pack(s)</StyledTableCell>
            <StyledTableCell>Unité(s)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
          <StyledTableRow >
          <StyledTableCell component="th" scope="row" align='center' colSpan={3}>
            TOTAL TTC : {Total.toFixed(2)} Dhs
          </StyledTableCell>
            </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </DialogContent>
    <DialogActions>
    <Button color="error" onClick={handleClose}>Fermer</Button>
    </DialogActions>
    </Dialog>
    
  )
}
