import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    modalShow : false,
    ajoutsuccess : false,
    listVendeurs : [],
    list : [],
    ajoutOk : false,
}

const Clotures = createSlice({
    name : 'CloturesData',
    initialState,
    reducers :{
        showmodal:(state,action) =>{
            state.modalShow=action.payload
        },
        ajoutsuccess:(state,action) =>{
            state.ajoutsuccess=action.payload
        },
        getList:(state,action) =>{
            state.list=action.payload
        },
        getvendeurs:(state,action) =>{
            state.listVendeurs=action.payload
        },
        ajout:(state,action) =>{
            state.ajoutOk=action.payload
    },
    addOne:(state,action) =>{
        state.list.push(action.payload)
},
remove: (state, action) => {    
    const id = action.payload;
    state.list=state.list.filter(element => element._id !== id);
  }
    }
})

export const {showmodal,getList,ajout,ajoutsuccess,getvendeurs,addOne,remove} = Clotures.actions
export default Clotures.reducer;