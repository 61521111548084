import { Box, Button, Chip, Container, FormControl, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import Apercu from './Apercu';
import { useDispatch } from 'react-redux';
import { ajoutsuccess,showapercu } from "./RetourcamionSlice";
export default function NouveauretourCamion() {
    const userData = useSelector(state => state.userData);
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;
    const retourData = useSelector(state => state.retourscamionData);
    const ListVendeur = retourData.listVendeurs;
    const ListCamion = retourData.listCamions

    const [Progession, setProgession] = useState(false);
    const [valType, setvalType] = useState('');
    const [Chargement, setChargement] = useState(false);
    const [rechercheInput,setRchercheInput] = useState('');
    const [valMarque, setMarque] = useState('Tout');
    const [valCategorie, setCategorie] = useState('Tout');
    const [listMarque,setListMarque] = useState([]);
    const [listCat,setListCat] = useState([]);
    const [listProduits, setListProduits] = useState([]);
    const [valVendeurs, setvalVendeurs] = useState('');
    const [valCamions, setvalCamions] = useState('');
  
    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };

    const dispatch= useDispatch();
  
    const handleVendeurChange = (event) => {
      setvalVendeurs(event.target.value);
      };

    const GetPack =(nb_unite,qte_pack) => Math.floor(nb_unite / qte_pack)
    const GetUnite =(nb_unite,qte_pack) => nb_unite % qte_pack
  
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;
  
    const [selectedDate, setSelectedDate] = useState(todayDate);

    const navigate = useNavigate();
    const vendeurField = useRef('')
    const camionField = useRef('')
    const typeField = useRef('')
    const remarqueField = useRef('')
    const rechercheField = useRef('')
    const dateField = useRef('')
    let listAchats=useRef([]);

    const handleCamionsChange = (event) => {
      listAchats.current=[]
      setvalCamions(event.target.value);
      getProduits(event.target.value)
      };
  
    const formatDateToIso = (dateString) => {
      const formattedDate = new Date(`${dateString}T00:00:00Z`);
      const hours = new Date().getHours();
      const minutes = new Date().getMinutes();
      formattedDate.setHours(hours, minutes);
      return formattedDate.toISOString();
      };

      const removeZeroStock = (list) => {
        return list.current.filter(item => item.totalunite !== 0);
      };
    const ShowApercu =() =>{
        dispatch(showapercu(true))
   }

   const formatDateToFrench = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleTypeChange =(event) =>{
    setvalType(event.target.value);
  }

  const Recherche=(event) =>{
    const recherche=rechercheField.current.value
    if(recherche!==rechercheInput){
      setRchercheInput(recherche)
       }
  }

  const handleMarqueChange = (event) => {
    setMarque(event.target.value);
  };

  const handleCategorieChange = (event) => {
    setCategorie(event.target.value);
  }

    const getMarque=async () => {
      setChargement(true)
        try {
        const response = await axios.post(`${apiUrl}marques/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListMarque(response.data);
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false)
  }
    const getCategorie=async () => {
        try {
        const response = await axios.post(`${apiUrl}categories/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        setListCat(response.data);
        }
     catch (error) {
      navigate('/');    
    }
    }

    const getProduits=async (idcamion) => {
        setProgession(true);
        try {
        const response = await axios.post(`${apiUrl}stockcamion/${id}/${apitoken}/${idcamion}`,
          {
            timeout: 20000,
          }
        );
        const data = response.data.map(product => ({
          ...product,pack:0,unite: 0
        }));
        setListProduits(data);
        }
     catch (error) {
      navigate('/');    
    }
    setProgession(false);
    }

    const StockUpdate2 = (value,idprod,event) => {
      const qte = value
      const id = idprod

      const updatedListProduits = listProduits.map(product => {
          if (product._id === id) {
              product.unite = qte
              const _id=product._id
              const designation=product.designation
              const pack=product.pack
              let unite = product.unite
              const qte_pack = product.qte_pack
              const marque = product.marque
              const categorie = product.categorie
              const prix_vente = product.prix_vente
              let totalunite = (parseInt(product.pack) * parseInt(product.qte_pack)) + parseInt(product.unite)
              if(totalunite>parseInt(product.totalunite)){
                alert('Stock insuffisant')
                event.target.value=0
                product.unite = 0
                unite=0
                totalunite = (parseInt(product.pack) * parseInt(product.qte_pack)) + parseInt(product.unite)
              }
              const valeurs={_id,designation,pack,unite,totalunite,qte_pack,marque,categorie,prix_vente}
              // Vérifier si l'_id existe déjà dans le tableau
              const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
              if (existingProductIndex !== -1) {
                  // Mettre à jour la valeur du stock et les unités
                  listAchats.current[existingProductIndex].unite = unite;
                  listAchats.current[existingProductIndex].totalunite = totalunite;
                } else {
                  // Ajouter le nouveau produit au tableau
                  listAchats.current.push({...valeurs});
                }
              return { listAchats };
          }
          return product;
      });
      
  };

    const StockUpdate = (value,idprod,event) => {
        const qte = value;
        const id = idprod;

        const updatedListProduits = listProduits.map(product => {
            if (product._id === id) {
                product.pack = qte
                const _id=product._id
                const designation=product.designation
                let pack=product.pack
                const unite = product.unite
                const qte_pack = product.qte_pack
                const marque = product.marque
                const categorie = product.categorie
                const prix_vente = product.prix_vente
                let totalunite = (parseInt(product.pack) * parseInt(product.qte_pack)) + parseInt(product.unite)
                if(totalunite>parseInt(product.totalunite)){
                  alert('Stock insuffisant')
                  event.target.value=0
                  product.pack = 0
                  pack=0
                  totalunite = (parseInt(product.pack) * parseInt(product.qte_pack)) + parseInt(product.unite)
                }
                const valeurs={_id,designation,pack,unite,totalunite,qte_pack,marque,categorie,prix_vente}
                // Vérifier si l'_id existe déjà dans le tableau
                const existingProductIndex = listAchats.current.findIndex(item => item._id === _id);
                if (existingProductIndex !== -1) {
                    // Mettre à jour la valeur du stock et les unités
                    listAchats.current[existingProductIndex].pack = pack;
                    listAchats.current[existingProductIndex].totalunite = totalunite;
                  } else {
                    // Ajouter le nouveau produit au tableau
                    listAchats.current.push({...valeurs});
                  }
                return { listAchats };

           }
            return product;
        });
        
    };

    function ShowList() {
        if(listProduits.length > 0){
            let listFiltre=listProduits
            if(rechercheInput.length > 2) {
                listFiltre = listFiltre.filter(produit => produit.designation.toLowerCase().includes(rechercheInput.toLowerCase()))
            }
            if(valMarque!=="Tout") {
                listFiltre = listFiltre.filter(produit => produit.marque === valMarque)
            }
      
            if(valCategorie!=="Tout") {
              listFiltre = listFiltre.filter(produit => produit.categorie === valCategorie)
          }
        return listFiltre.map(Produit => {
            const currentQte = listAchats.current.find(item => item._id === Produit._id);
            let stock="0";
            let unite="0";
            if(currentQte){
                stock=currentQte.pack;
                unite=currentQte.unite;
            }

            return(
            
            <StyledTableRow key={Produit._id}>
            <StyledTableCell component="th" scope="row">
              {Produit.designation}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            <TextField
       onChange={(event) => StockUpdate(event.target.value,Produit._id,event)}
        defaultValue={stock}
        id={Produit._id}
        label="Packs"
        size='normal'
        type='number'
        variant="outlined"
      />
      <Box mt={1} width={'100%'} alignContent={'center'} justifyContent={'left'}>
      <Stack direction="row" marginLeft={'50px'} spacing={1} justifyContent={'left'}>
      <Chip label={`Disponible : ${GetPack(Produit.totalunite,Produit.qte_pack)}`} />
      </Stack>
      </Box>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
            <TextField
        onChange={(event) => StockUpdate2(event.target.value,Produit._id,event)}
        defaultValue={unite}
        id={Produit._id}
        label="Unités"
        size='normal'
        type='number'
        variant="outlined"
      />
      <Box mt={1} width={'100%'} alignContent={'center'} justifyContent={'left'}>
      <Stack direction="row" marginLeft={'50px'} spacing={1} justifyContent={'left'}>
      <Chip label={`Disponible : ${GetUnite(Produit.totalunite,Produit.qte_pack)}`} />
      </Stack>
      </Box>
            </StyledTableCell>
            </StyledTableRow>
            )
            
        });
        
    }
    else{
        return <tr>
            <td colSpan={6} align='center'>Pas de données</td>
        </tr>;
    }
    
    }    


    const Ajouter = async () => {

      const idvendeur=vendeurField.current.value
      if(idvendeur==="")
      {
        alert("veillez sélectionner un vendeur")
        return
      }
      let vendeur = ListVendeur.find(item => item._id === idvendeur);
      vendeur = vendeur.nom
      vendeur={
          "id" : idvendeur,
          "nom" : vendeur,
      };

      const idcamion=camionField.current.value
      if(idcamion==="")
      {
        alert("veillez sélectionner un camion")
        return
      }
      let camion = ListCamion.find(item => item._id === idcamion);
      camion = camion.designation
      camion={
          "id" : idcamion,
          "designation" : camion,
      };


      const remarque=remarqueField.current.value
      const type = typeField.current.value
      if(type==="")
      {
        alert("Vous devez sélectionner un type.");
        return;
      }

      const date = formatDateToIso(dateField.current.value)
      const produits = removeZeroStock(listAchats)
      setProgession(true);
      try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}new/retourcamion/${id}/${apitoken}`,
        {
          vendeur,
          camion,
          remarque,
          type,
          produits,
          date
          },
          {
            timeout: 20000,
          }
        );
          dispatch(ajoutsuccess(true));
          navigate('/home/retourcamion', { replace: true });
      }
   catch (error) {
    navigate('/'); 
  }
  setProgession(false);
  }    

    useEffect(() =>{
        getMarque();
        getCategorie();
        },[]);

  return (

    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
      Nouveau retour défectueux
        </Typography>

        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} />
         :
          
         <Box display="flex" width="100%" mt={5}>

            <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Vendeurs</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="vendeur"
          inputRef={vendeurField}
          value={valVendeurs}
          label="Vendeurs"
          onChange={handleVendeurChange}
        >
          {ListVendeur.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.nom}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 150,marginLeft:2 }}>
          <InputLabel id="demo-simple-select-label">Camions</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          inputRef={camionField}
          value={valCamions}
          label="Camions"
          onChange={handleCamionsChange}
        >
          {ListCamion.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
         <FormControl sx={{ minWidth: 150,marginLeft:2 }}>
     <InputLabel id="demo-simple-select-label">Type retour</InputLabel>
     <Select
   
     labelId="demo-simple-select-label"
     id={valType}
     inputRef={typeField}
     value={valType}
     label="Type retour"
     onChange={handleTypeChange}
   >
     <MenuItem value='Produits périmés'>Produits périmés</MenuItem>
     <MenuItem value='Produits dégradés'>Produits dégradés</MenuItem>
     </Select>
     </FormControl>

         <TextField sx={{ marginLeft: 2 }}
       inputRef={remarqueField}
       id="input-with-icon-textfield"
       label="Remarque"
       size='normal'
       InputProps={{
   }}
   variant="outlined"
 />                           


         <TextField sx={{ marginLeft: 2 }}
       inputRef={dateField}
       value={selectedDate}
       onChange={handleDateChange}
       id="input-with-icon-textfield"
       label="Date"
       size='normal'
       type='date'
   variant="outlined"
 />                         
</Box> 
}
    <Typography variant="h4" align='center' color="primary" sx={{ marginTop: '40px' }}>
        Produits
        </Typography> 

        <Box display="flex" width="100%" mt={5}>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={rechercheField}
        id="input-with-icon-textfield"
        label="Recherche"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      
       }
<Box sx={{ marginLeft: 2 }}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
         <FormControl sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Marque</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={valMarque}
         label="Marque"
         onChange={handleMarqueChange}
       >
        <MenuItem value='Tout'>Tout</MenuItem>
         {listMarque.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>
}
</Box>
<Box sx={{ marginLeft: 2 }}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCategorie}
          label="Catégorie"
          onChange={handleCategorieChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {listCat.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.designation}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
}
</Box>
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell>Pack(s)</StyledTableCell>
            <StyledTableCell>Unité(s)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
<Box display="flex" width="100%" mt={2}>
<Box style={{ marginLeft: 'auto' }}>

{Chargement ? 
<Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
:

<Button sx={{ marginRight: '20px' }} variant="contained" size='large' color="primary" startIcon={<RemoveRedEyeOutlinedIcon/>} onClick={ShowApercu} >
Aperçu
</Button>  
} 
{Chargement ? 
<Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
:

<Button variant="contained" disabled={Progession} size='large' color="success" startIcon={<CloudDoneOutlinedIcon/>} onClick={Ajouter} >
Enregistrer
</Button>
} 
</Box>
</Box>
<Apercu Chargements={listAchats.current} />
    </Container>
  )
}
