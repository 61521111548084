import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    apercuShow : false,
    ajoutsuccess : false,
    listFournisseurs : [],
}

const Commandes = createSlice({
    name : 'CommandesData',
    initialState,
    reducers :{
        ajoutsuccess:(state,action) =>{
            state.ajoutsuccess=action.payload
        },
        showapercu:(state,action) =>{
            state.apercuShow=action.payload
        },
        fournisseur:(state,action) =>{
            state.listFournisseurs=action.payload
        },
    }
})

export const {ajoutsuccess,showapercu,fournisseur} = Commandes.actions
export default Commandes.reducer;