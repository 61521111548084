import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {ajoutsuccess} from './FactureSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function DateFacture({Show,toggleApercu ,listFacture}) {
    const userData = useSelector(state => state.userData);
    const apiUrl = process.env.REACT_APP_API_URL;
    const id = userData.id;
    const apitoken = userData.apitoken;
    
    
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;

    const [LastFacture,setLastFacture] = useState('');
    const [selectedDate, setSelectedDate] = useState(todayDate);

    const handleReglementChange = (event) => {
      setModeRegelement(event.target.value);
      };

      const handleFactureChange = (event) => {
        setLastFacture(event.target.value);
        };
    const [modeRegelement, setModeRegelement] = useState('Espèce');

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();


    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };

    const formatDateToFrench = (dateString) => {
      const [date] = dateString.split('T');
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    };

    const GetFacture = async (idfacture,date,facture) =>{
      try {
          const dateFr=formatDateToFrench(date)
          const response = await axios.get(`${apiUrl}generer/facture/${id}/${apitoken}/${idfacture}`,{
            responseType: 'blob',
          });
          // Créez un lien pour télécharger le PDF
          const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',`Facture N° ${facture} du ${dateFr}.pdf`);
          document.body.appendChild(link);
          link.click();
      }
          catch (error) {
              console.error('Error generating fsortie:', error);
            }
  }

      const Ajouter =async()=>{
        const date =selectedDate
        const ventes = listFacture
        const reglement = modeRegelement
        const facture = LastFacture.toString()

        setChargement(true)
        try {
            const response = await axios.post(`${apiUrl}new/facturevente/${id}/${apitoken}`,
                {
                  ventes,
                  reglement,
                  facture,
                  date
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                  GetFacture(response.data.id,date,facture)
                  toggleApercu()
                  dispatch(ajoutsuccess(true)); 
                  navigate('/home/factures', { replace: true });                   
                }

            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

    const getLastFacture = async () =>{
      try {
      const response = await axios.post(`${apiUrl}lastfacture/${id}`,
        {
          timeout: 20000,
        }
      );
      setLastFacture(response.data);
  }
      
          catch (error) {
              console.error('Error:', error);
          }
  }

  useEffect(() =>{
    getLastFacture()
    },[]);

  return (
<Dialog
        open={Show}
        onClose={toggleApercu}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Nouvelle facture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Entrez la dare de la nouvelle facture
          </DialogContentText>
          <Box mt={4} width={300}>
          <TextField 
          
          fullWidth
         value={selectedDate}
         onChange={handleDateChange}
         id="input-with-icon-textfield"
         label="Date facture"
         size='normal'
         type='date'
     variant="outlined"
   />  
   </Box>
   <Box mt={4} width={300}>
<TextField 
          fullWidth
         value={LastFacture}
         onChange={handleFactureChange}
         id="input-with-icon-textfield"
         label="N° Facture"
         size='normal'
         type='text'
     variant="outlined"
   />  
   </Box>
   <Box mt={4} width={300}>
  <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Mode règlement</InputLabel>
          <Select
          labelId="demo-simple-select-label"
          id="vendeur"
          value={modeRegelement}
          label="Mode règlement"
          onChange={handleReglementChange}
        >
          <MenuItem value="Espèce">Espèce</MenuItem>
          <MenuItem value="Chèque">Chèque</MenuItem>
          <MenuItem value="Virement">Virement</MenuItem>

          </Select>
          </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error">Annuler</Button>
          <Button disabled color="success">Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={toggleApercu}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
