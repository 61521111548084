import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    apercuShow : false,
    ajoutsuccess : false,
    modifsuccess : false,
    current : [],
}
const Retours = createSlice({
    name : 'RetoursData',
    initialState,
    reducers :{
        ajoutsuccess:(state,action) =>{
            state.ajoutsuccess=action.payload
        },
        idretour:(state,action) =>{
            state.current=action.payload
        },
        showapercu:(state,action) =>{
            state.apercuShow=action.payload
        },
        modifsuccess:(state,action) =>{
            state.modifsuccess=action.payload
        },
    }
})
export const {ajoutsuccess,idretour,showapercu,modifsuccess} = Retours.actions
export default Retours.reducer;