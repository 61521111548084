import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current } from './marqueSlice';
import {Box, LinearProgress, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Modifier() {
    const userData = useSelector(state => state.userData);
    const marqueData = useSelector(state => state.marqueData);
    const showModal = marqueData.modalModifShow;
    const currentelement = marqueData.current;

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const designationField = useRef('')

    const ChampUpdate = () =>{
      const _id=currentelement._id;
      const designation=designationField.current.value;
      const data={_id,designation};
      dispatch(current(data)); 
    }


      const handleClose = () => {
        dispatch(modalModifShow(false));
      };
      const Ajouter =async()=>{
        const designation =designationField.current.value.trim()
        const idmarque = currentelement._id;
        if(designation===""){
            alert("Champ marque vide");
            return
        }
        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}edit/marque/${id}/${apitoken}/${idmarque}`,
                {
                    designation
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const data={idmarque,designation};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));
                    
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Modifier une marque</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Entrez la Désignation de la marque.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="marque"
            label="Marque"
            type="text"
            fullWidth
            variant="standard"
            inputRef={designationField}
            value={currentelement.designation}
            onChange={ChampUpdate}
          />
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
