import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,addOne} from './promoSlice';
import {Box, Chip, FormControl, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Ajouter() {
    const userData = useSelector(state => state.userData);
    const promoData = useSelector(state => state.promosData);
    const listProduit = promoData.listProduit
    const showModal = promoData.modalShow;

    const navigate = useNavigate();
    const theme = useTheme();
    
    const [chargement,setChargement] = useState(false);
    const [valProduitCible, setvalProduitCible] = useState('');
    const [valProduitPromo, setvalProduitPromo] = useState('');
    const [personName, setPersonName] = useState([]);
    const dispatch= useDispatch();

    const qteCibleField = useRef('')
    const qtePromoField = useRef('')
    const remarqueField = useRef('')
    const secteursField = useRef([])

    const handleCatChange = (event) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleProdCibleChange = (event) => {
      setvalProduitCible(event.target.value);
    };

    const handleProdPromoChange = (event) => {
      setvalProduitPromo(event.target.value);
    };

      const handleClose = () => {
        dispatch(showmodal(false));
      };

      const Ajouter =async()=>{
        let qtecible =qteCibleField.current.value
        qtecible=qtecible.replace(",", ".");

        let qtepromo =qtePromoField.current.value
        qtepromo=qtepromo.replace(",", ".");

        const remarque = remarqueField.current.value.trim()

        if(valProduitCible==="" || valProduitPromo==="" || remarque==="" || qtecible==="" || qtepromo===""){
          alert("Veuillez remplir les champs manquants");
          return
        }

        let valeur = listProduit.find(prod => prod._id === valProduitCible);
        const prodCible = {
          id: valeur._id,
          designation: valeur.designation
        };
        valeur = listProduit.find(prod => prod._id === valProduitPromo);
        const prodPromo = {
          id: valeur._id,
          designation: valeur.designation,
          qte_pack  : valeur.qte_pack
        };
        const etat = "Active"

        const categories = secteursField.current.value
        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}new/promo/${id}/${apitoken}`,
                {
                  prodCible,
                  qtecible,
                  prodPromo,
                  qtepromo,
                  remarque,
                  categories,
                  etat

                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const data={_id,prodCible,qtecible,prodPromo,qtepromo,remarque,categories,etat};
                    dispatch(showmodal(false));
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    setPersonName([])
                    
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Ajouter une promotion</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez remplir la fiche de la nouvelle promotion
          </DialogContentText>
          <Box mt={2}>
         <FormControl fullWidth sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Produit cible</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         fullWidth
         required
         value={valProduitCible}
         label="Produit cible"
         onChange={handleProdCibleChange}
       >
         {listProduit.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>

         <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="qtepromo"
            name="quantite"
            label="Quantité Cible"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={qteCibleField}
          />
          </Box>

</Box>
<Box mt={2}>
         <FormControl fullWidth sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Produit promo</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         fullWidth
         required
         value={valProduitPromo}
         label="Produit promo"
         onChange={handleProdPromoChange}
       >
         {listProduit.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>

</Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="quantite"
            label="Quantité Promo"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={qtePromoField}
          />
          </Box>

          <Box mt={2}>
          <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label">Catégories</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleCatChange}
          inputRef={secteursField}
          input={<OutlinedInput id="select-multiple-chip" label="Catégories" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
            <MenuItem
              key="AG"
              value="AG"
              style={getStyles("AG", personName, theme)}
            >
              AG
            </MenuItem>

            <MenuItem
              key="Café"
              value="Café"
              style={getStyles("Café", personName, theme)}
            >
              Café
            </MenuItem>

            <MenuItem
              key="Restaurant"
              value="Restaurant"
              style={getStyles("Restaurant", personName, theme)}
            >
              Restaurant
            </MenuItem>

            <MenuItem
              key="Dépôt de lait"
              value="Dépôt de lait"
              style={getStyles("Dépôt de lait", personName, theme)}
            >
              Dépôt de lait
            </MenuItem>

            <MenuItem
              key="Kiosque"
              value="Kiosque"
              style={getStyles("Kiosque", personName, theme)}
            >
              Kiosque
            </MenuItem>

            <MenuItem
              key="Boulangerie"
              value="Boulangerie"
              style={getStyles("Boulangerie", personName, theme)}
            >
              Boulangerie
            </MenuItem>
        </Select>
      </FormControl>
          </Box>

          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="designation"
            label="Désignation"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={remarqueField}
          />
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
