import { Box, Card, Container, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

export default function VendeurStats() {

  const userData = useSelector(state => state.userData);
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const [TotalVentes, setTotalVentes] = useState("--");
  const [ValeurCredit, setValeurCredit] = useState("--");


    const getChiffre=async () => {
      try {
      const response = await axios.post(`${apiUrl}statistiques/vendeur/chiffre/${id}/${apitoken}`,
        {
          timeout: 20000,
        }
      );
      setTotalVentes(response.data.total.toFixed(2));
      }
   catch (error) {  
  }
  }

const getValeurCredit=async () => {
  try {
  const response = await axios.post(`${apiUrl}statistiques/vendeur/valeurcredit/${id}/${apitoken}`,
    {
      timeout: 20000,
    }
  );
  setValeurCredit(response.data.total.toFixed(2));
  }
catch (error) {  
}
}


  useEffect(() =>{
    getChiffre();
    getValeurCredit();

    const interval = setInterval(() => {
      getChiffre();
      getValeurCredit();
    }, 3630000);

    return () => clearInterval(interval);

    },[]);


  return (
    <Container maxWidth="lg" >
<Box mt={5} sx={{ flexGrow: 1 }} width={'100%'} >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

        
        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/sales.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#00B5B8',fontWeight:'bold'}}>
            {TotalVentes} Dhs
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Ventes du jour
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>
         
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/credit.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#FFA808',fontWeight:'bold'}}>
            {ValeurCredit} Dhs
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Valeur crédits
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>
        </Grid>
      </Grid>
    </Box>
    </Container>
  )
}
