import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {ajout,showmodal} from './clientSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Ajouter() {
    const userData = useSelector(state => state.userData);
    const listSecteur=userData.secteurs
    const clientsData = useSelector(state => state.clientsPDAData);
    const showModal = clientsData.modalShow;
    const [valSecteur, setvalSecteur] = useState('');
    const [valCategorie, setvalCategorie] = useState('AG');


    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const nomField = useRef('')
    const prenomField = useRef('')
    const villeField = useRef('')
    const adresseField = useRef('')
    const telField = useRef('')

    const handleSecteurChange = (event) => {
      setvalSecteur(event.target.value);
    };
    const handleCategorieChange = (event) => {
      setvalCategorie(event.target.value);
    };
      const handleClose = () => {
        dispatch(showmodal(false));
      };
      const Ajouter =async()=>{
        const nom =nomField.current.value.trim()
        const prenom = prenomField.current.value.trim()
        if(nom===""){
          alert("Champ nom vide");
          return
      }

      if(valSecteur===""){
        alert("Champ secteur vide");
        return
    }

        const ville = villeField.current.value
        const adresse = adresseField.current.value
        const categorie= valCategorie
        const tel = telField.current.value

        if(ville===""){
          alert("Champ ville vide");
          return
      }
        const ice =""
        const valeur = listSecteur.find(secteur => secteur.id === valSecteur);

        const secteur = {
          id: valeur.id,
          designation: valeur.designation
        };

        const location ={}

        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}new/client/${id}/${apitoken}`,
                {
                  nom,
                  prenom,
                  tel,
                  adresse,
                  categorie,
                  ville,
                  ice,
                  secteur,
                  location,
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    dispatch(showmodal(false));
                    dispatch(ajout(true));                    
                }
            else if(response.data.status==="exist")
             {
                alert("Client déja existant");
            }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Ajouter une Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez remplir la fiche du nouveau client
          </DialogContentText>
          <TextField sx={{ marginTop: '20px' }}
            autoFocus
            required
            margin="dense"
            id="name"
            name="Nom"
            label="Nom"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={nomField}
          />
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="prenom"
            name="prenom"
            label="Prénom"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={prenomField}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="ville"
            name="ville"
            label="Ville"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={villeField}
          />
          <Box mt={2}>
            <TextField
            autoFocus
            margin="dense"
            id="adresse"
            name="adresse"
            label="Adresse"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={adresseField}
          />
          </Box>
          <Box mt={2}>

<FormControl fullWidth sx={{ minWidth: 150 }}>
<InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
<Select

labelId="demo-simple-select-label"
id="demo-simple-select"
fullWidth
value={valCategorie}
label="Catégorie"
onChange={handleCategorieChange}
>
<MenuItem value="AG">AG</MenuItem>
<MenuItem value="Café">Café</MenuItem>
<MenuItem value="Restaurant">Restaurant</MenuItem>
<MenuItem value="Dépôt de lait">Dépôt de lait</MenuItem>
<MenuItem value="Kiosque">Kiosque</MenuItem>
<MenuItem value="Boulangerie">Boulangerie</MenuItem>
</Select>
</FormControl>

</Box>
          </Box>
          <Box mt={2}>

         <FormControl fullWidth sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Secteur</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         fullWidth
         value={valSecteur}
         label="Secteur"
         onChange={handleSecteurChange}
       >
         {listSecteur.map((item) => (
         <MenuItem key={item.id} value={item.id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>

</Box>
<Box mt={2}>
            <TextField
            autoFocus
            margin="dense"
            id="tel"
            name="tel"
            label="Tel."
            type="text"
            fullWidth
            variant="outlined"
            inputRef={telField}
          />
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
