import { Alert, Box, Button, Chip, Container, FormControl, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,getList,ajout,modalModifShow,modif,current,remove,listclient } from "./chequeSlice";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Ajouter from './Ajouter';
import Modifier from './Modifier';

import SearchIcon from '@mui/icons-material/Search';

export default function Cheques() {
  const userData = useSelector(state => state.userData);
  const [Chargement, setChargement] = useState(false);
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;
  const typeCompte = userData.type

  const chequeData = useSelector(state => state.chequesData);
  const listCheque = chequeData.list;
  const ajoutSuccess = chequeData.ajoutOk
  const modifSuccess = chequeData.modifOk
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [Progession, setProgession] = useState(false);
  const [rechercheNom,setRchercheNom] = useState('');
  const [recherchePrenom,setrecherchePrenom] = useState('');
  const [valEtat, setvalEtat] = useState('Tout');

  const rechercheNomField = useRef('')
  const recherchePrenomField = useRef('')

  const dispatch= useDispatch();
  const navigate = useNavigate();

  const handleEtatChange = (event) => {
    setvalEtat(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajout(false));
    dispatch(modif(false));
    setRemoveSuccess(false);
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const suppression = async (idcheque)=> {
        setProgession(true);

        try {
            const response = await axios.post(`${apiUrl}delete/cheque/${id}/${apitoken}/${idcheque}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idcheque));
                    setRemoveSuccess(true);
                    setProgession(false);
                }
        } catch (error) {
          navigate('/');
        }
    }
    
      const supprimer = (idcheque,nom,prenom) => {
        let result = window.confirm(`Supprimer le chèque de ${nom} ${prenom} ?`);
        if (result === true) {
            suppression(idcheque);
        }
    }

    const modifier = (id) => {
      dispatch(modalModifShow(true));
      const valeur = listCheque.find(item => item._id === id);
      dispatch(current(valeur));
  };

  const formatDateToFrench = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };

      function ShowList() {
        if(listCheque.length > 0){
          let listFiltre=listCheque
          if(rechercheNom.length > 2) {
            listFiltre = listFiltre.filter(cheque => cheque.client.nom.toLowerCase().includes(rechercheNom.toLowerCase()))
        }
        if(recherchePrenom.length > 2) {
          listFiltre = listFiltre.filter(cheque => cheque.client.prenom.toLowerCase().includes(recherchePrenom.toLowerCase()))
      }
      if(valEtat !=="Tout") {
        listFiltre = listFiltre.filter(cheque => cheque.etat===valEtat)
    }
          return listFiltre.map(Cheque => {
            let StyleColor = "success"
            if(Cheque.etat==="Enregistré"){
              StyleColor = "secondary"
            }
            if(Cheque.etat==="En attente d'encaissement"){
                StyleColor = "warning"
              }

            return(
              <StyledTableRow key={Cheque._id}>
              <StyledTableCell component="th" scope="row">
                {Cheque.numero}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Cheque.client.nom} {Cheque.client.prenom} / {Cheque.client.client_code}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Cheque.montant}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {Cheque.remarque}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <Stack direction="row" spacing={1} >
              <Chip label={Cheque.etat} color={StyleColor} />
              </Stack>
          <Stack direction="row" spacing={1}></Stack>

              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <td>{Cheque.date!=="" ? formatDateToFrench(Cheque.date) : ''}</td>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              <td>{Cheque.date_ajout!=="" ? formatDateToFrench(Cheque.date_ajout) : ''}</td>
              </StyledTableCell>
              
              <StyledTableCell align="right">
              { typeCompte === "admin" ?
              <>
              <Button sx={{ width: '135px' }} variant="outlined" color='secondary' startIcon={<EditIcon />} onClick={() => modifier(Cheque._id)}>
               Modifier
              </Button>       
              <Button sx={{ marginTop: '10px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Cheque._id,Cheque.client.nom,Cheque.client.prenom)}>
               Supprimer
              </Button>
              </>

              :
              ""
              }
              </StyledTableCell>

            </StyledTableRow>
            )

          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={8} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

      const getClients=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}clients/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(listclient(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

    const getCheques=async () => {
      setChargement(true);
      try {
      const response = await axios.post(`${apiUrl}cheques/${id}/${apitoken}`,
        {
          timeout: 20000,
        }
      );
      const today = new Date();
      const updatedData = response.data.map(item => {
        const itemDate = new Date(item.date);
        const itemEtat = item.etat;
        if (itemDate <= today && itemEtat==="Enregistré") {
            return { ...item, etat: "En attente d'encaissement" };
        }
        return item;
    });
    dispatch(getList(updatedData));
      }
   catch (error) {
    navigate('/');    
  }
  setChargement(false);
  }
    const Recherche=(event) =>{
      const nom=rechercheNomField.current.value
      const prenom=recherchePrenomField.current.value
      if(nom!==rechercheNom){
        setRchercheNom(nom)
         }
         if(prenom!==recherchePrenom){
          setrecherchePrenom(prenom)
           }
    }

      useEffect(() =>{
        getCheques();
        getClients();
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Chèques
        </Typography>

        <Box display="flex" width="100%" mt={5}>
        {Chargement ? 
        <Skeleton  variant="rounded" height={56} width={200} />
         :
        <TextField
        sx={{width : '200px'}}
        onKeyUp={Recherche}
        inputRef={rechercheNomField}
        id="input-with-icon-textfield"
        label="Recherche Nom"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
       }
             <Box sx={{ marginLeft: 2 }}>
             {Chargement ? 
        <Skeleton  variant="rounded" height={56} width={200} />
         :
        <TextField
        sx={{width : '200px'}}
        onKeyUp={Recherche}
        inputRef={recherchePrenomField}
        id="input-with-icon-textfield2"
        label="Recherche Prénom"
        size='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
       }
              
      </Box>

      <Box sx={{ marginLeft: 2 }}>
{Chargement ? 
        <Skeleton  variant="rounded" height={56} width={180} />
         :
<FormControl sx={{ minWidth: 220 }}>
          <InputLabel id="demo-simple-select-label">Etat</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valEtat}
          label="Etat"
          onChange={handleEtatChange}
        >
          <MenuItem value="Tout">Tout</MenuItem>
          <MenuItem value="Enregistré">Enregistré</MenuItem>
          <MenuItem value="En attente d'encaissement">En attente d'encaissement</MenuItem>
          <MenuItem value="Encaissé">Encaissé</MenuItem>

          </Select>
          </FormControl>
}
</Box>

       <Box style={{ marginLeft: 'auto' }}>
                {Chargement ? 
        <Skeleton  variant="rounded" height={45} width={241} />
         :
        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={() =>dispatch(showmodal(true))} >
    Ajouter un Chèque
  </Button>   
    }
    </Box>
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
          <StyledTableCell>Numéro</StyledTableCell>
            <StyledTableCell>Client</StyledTableCell>
            <StyledTableCell>Montant</StyledTableCell>
            <StyledTableCell>Remarque</StyledTableCell>
            <StyledTableCell>Etat</StyledTableCell>
            <StyledTableCell>Date d'encaissement</StyledTableCell>
            <StyledTableCell>Date d'ajout</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
          <Ajouter/>
          <Modifier/>

          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Chèque ajouté avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Chèque modifié avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Chèque supprimé avec succès
        </Alert>
      </Snackbar>
        </Container>
  )
}
