import { Alert, Box, Button, Container, FormControl, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';

import { useSelector,useDispatch } from 'react-redux';
import { ajoutsuccess,idchargement,modifsuccess,showapercu,getvendeurs,getcamions } from "./ChargementSlice";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Apercu from './Apercu';

export default function Chargements() {

  const ChargementData = useSelector(state => state.chargementsData);
  const ajoutSuccess = ChargementData.ajoutsuccess;
  const modifSuccess = ChargementData.modifsuccess;
  const ListVendeur = ChargementData.listVendeurs;
  const ListCamion = ChargementData.listCamions;
  const [Chargement, setChargement] = useState(false);
  const [listchargement, setListchargement] = useState([]);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [Progession, setProgession] = useState(false);
  const [valVendeurs, setvalVendeurs] = useState('Tout');
  const [valCamions, setvalCamions] = useState('Tout');

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const todayDate = `${year}-${month}-${day}`;

  const [selectedDate, setSelectedDate] = useState(todayDate);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const userData = useSelector(state => state.userData)
  const id = userData.id;
  const typeCompte = userData.type
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const dateChargementField = useRef('')

  const navigate = useNavigate();
  const dispatch= useDispatch();

  const handleVendeurChange = (event) => {
    setvalVendeurs(event.target.value);
  };
  const handleCamionChange = (event) => {
    setvalCamions(event.target.value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajoutsuccess(false));
    dispatch(modifsuccess(false));
    setRemoveSuccess(false);
  };

  const formatDateToFrench = (dateString) => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  const listChargements=useRef([]);

  const modifier = (id) => {
    const filtrechargement = listchargement.filter(list => list._id === id);
    dispatch(idchargement(filtrechargement));
    navigate('/home/editchargement');
  }

  const suppression = async (idchargement)=> {
    setProgession(true)
    
    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}delete/chargement/${id}/${apitoken}/${idchargement}`,
          {
            timeout: 20000,
          }
        );
        if(response.data.status==="ok")
            {
                const newlist=listchargement.filter(achat => achat._id !== idchargement);
                setListchargement(newlist);
                setRemoveSuccess(true);
            }
    } catch (error) {
      navigate('/');
    }
    setProgession(false)
}

const supprimer = (id) => {
  let result = window.confirm(`Supprimer ce chargement ?`);
  if (result === true) {
      suppression(id);
  }
}

const ShowApercu=async (idchargement) => {
  setProgession(true)
  try {
  const apiUrl = process.env.REACT_APP_API_URL;
  const response = await axios.post(`${apiUrl}list/chargement/${id}/${apitoken}/${idchargement}`,
    {
      timeout: 20000,
    }
  );
  listChargements.current = response.data.produits
  dispatch(showapercu(true))
  }
  catch (error) {
      console.error('Error:', error);    
  }
  setProgession(false)
}

const GetFsortie = async (idchargement,date,vendeur) =>{
  setProgession(true)
  try {
      const dateFr=formatDateToFrench(date)
      const response = await axios.get(`${apiUrl}generer/fsortie/${id}/${apitoken}/${idchargement}`,{
        responseType: 'blob',
      });
      // Créez un lien pour télécharger le PDF
      const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',`Bon de chargement ${vendeur} du ${dateFr}.pdf`);
      document.body.appendChild(link);
      link.click();
  }
      catch (error) {
          console.error('Error generating fsortie:', error);
        }
    setProgession(false)
}


const formatDate = (dateString) => {
  const [date] = dateString.split('T');
  const [year, month, day] = date.split('-');
  return `${year}-${month}-${day}`;
};

function ShowList() {
  if(listchargement.length > 0){
    let listFiltre=listchargement.filter(chargement => formatDate(chargement.date) === selectedDate)
    if(valVendeurs!=="Tout") {
      listFiltre = listFiltre.filter(chargement => chargement.vendeur["id"] === valVendeurs)
  }
  if(valCamions!=="Tout") {
    listFiltre = listFiltre.filter(chargement => chargement.camion["id"] === valCamions)
}
    return listFiltre.map(Chargement => {
      return(
        <StyledTableRow key={Chargement._id}>
        <StyledTableCell component="th" scope="row">
          {Chargement.vendeur.nom}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
        {Chargement.camion.designation}
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
        {Chargement.date!=="" ? formatDateToFrench(Chargement.date) : ''}
        </StyledTableCell>
        <StyledTableCell align="right">
          <Box>
        <Button sx={{ width:'140px' }} variant="outlined" color='secondary' startIcon={<VisibilityIcon />} onClick={() => ShowApercu(Chargement._id)}>
        Aperçu
        </Button> 
        <Button sx={{ marginLeft: '10px',width:'140px' }} variant="outlined" color='primary' startIcon={<PrintIcon />} onClick={() => GetFsortie(Chargement._id,Chargement.date,Chargement.vendeur.nom)}>
        Imprimer
        </Button> 
        </Box>
        { typeCompte === "admin" ? 
        <Box mt={1}>
        <Button sx={{ width:'140px' }} variant="outlined" color='warning' startIcon={<EditIcon />} onClick={() => modifier(Chargement._id)}>
         Modifier
        </Button>      
        <Button sx={{ marginLeft: '10px',width:'140px' }} variant="outlined" color='error' startIcon={<DeleteIcon />} onClick={() => supprimer(Chargement._id)}>
         Supprimer
        </Button>
        </Box>
        : 
        ''
    }
        </StyledTableCell>
      </StyledTableRow>
      )

    })
    }
    else {
      return (
        <StyledTableRow>
        <StyledTableCell align='center' colSpan={7} component="th" scope="row">
        Pas de données
        </StyledTableCell>
      </StyledTableRow>
      )
    }
    }

const GetChargements=async () => {
  setChargement(true)
    try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await axios.post(`${apiUrl}chargements/${id}/${apitoken}`,
      {
        timeout: 20000,
      }
    );
    setListchargement(response.data);
    }
  catch (error) {
    navigate('/');    
  }
  setChargement(false)
}

const Ajouter=() => {
  navigate('/home/nouveauchargement');
  }

  const getVendeurs=async () => {
    try {
    const response = await axios.post(`${apiUrl}vendeurs/${id}/${apitoken}`,
      {
        timeout: 20000,
      }
    );
    dispatch(getvendeurs(response.data));
    }
 catch (error) {
  navigate('/');    
}
}

const getCamions=async () => {
  try {
  const response = await axios.post(`${apiUrl}camions/${id}/${apitoken}`,
    {
      timeout: 20000,
    }
  );
  dispatch(getcamions(response.data));
  }
catch (error) {
navigate('/');    
}
}

  useEffect(() =>{
    GetChargements()
    getVendeurs();
    getCamions();
    },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Chargements
        </Typography>
        <Box display="flex" width="100%" mt={5}>

<Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Vendeurs</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valVendeurs}
          label="Vendeurs"
          onChange={handleVendeurChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {ListVendeur.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.nom}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
}
</Box>
<Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px',marginLeft:'20px' }}  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150,marginLeft:2 }}>
          <InputLabel id="demo-simple-select-label">Camions</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCamions}
          label="Camions"
          onChange={handleCamionChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {ListCamion.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
}
</Box>

<Box>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px',marginLeft:'20px' }} variant="rounded" height={56} width={150} />
         :
         <TextField sx={{ marginLeft: 2 }}
         inputRef={dateChargementField}
         value={selectedDate}
         onChange={handleDateChange}
         id="input-with-icon-textfield"
         label="Date chargement"
         size='normal'
         type='date'
     variant="outlined"
   />  
}
</Box>


            <Box style={{ marginLeft: 'auto' }}>

               {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} width={241} />
         :

        <Button variant="contained" size='large' color="primary" startIcon={<AddIcon/>} onClick={Ajouter} >
    Nouveau chargement
  </Button>  
} 
</Box>
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
          { Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Vendeur</StyledTableCell>
            <StyledTableCell>Camion</StyledTableCell>
            <StyledTableCell>Date chargement</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}

          <Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Chargement ajouté avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={modifSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Chargement modifié avec succès
        </Alert>
      </Snackbar>

      <Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Chargement supprimé avec succès
        </Alert>
      </Snackbar>
      <Apercu Chargements={listChargements} />
        </Container>
  )
}
