import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,addOne} from './ProduitsSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Ajouter() {
    const userData = useSelector(state => state.userData);
    const produitsData = useSelector(state => state.produitsData);
    const showModal = produitsData.modalShow;
    const listCat = produitsData.listCat;
    const listMarque = produitsData.listMarque;

    const [valMarque, setMarque] = useState('');
    const [valCategorie, setCategorie] = useState('');

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const designationField = useRef('')
    const prixAchatField = useRef('')
    const prixVenteField = useRef('')
    const qtePackField = useRef('')

    const ViderChamps = () => {
      designationField.current.value = '';
      prixAchatField.current.value = '';
      prixVenteField.current.value = '';
      qtePackField.current.value = '';
  }
    
    const handleMarqueChange = (event) => {
      setMarque(event.target.value);
    };
    const handleCategorieChange = (event) => {
      setCategorie(event.target.value);
    };
    

      const handleClose = () => {
        dispatch(showmodal(false));
        ViderChamps();
      };
      const Ajouter =async()=>{
        const designation =designationField.current.value.trim()

        if(designation==="" || valCategorie==="" || valMarque===""){
            alert("Veuillez remplir les champs manquants");
            return
        }
        let valeur = listCat.find(cat => cat._id === valCategorie);
        const categorie = {
          id: valeur._id,
          designation: valeur.designation
        };
        valeur = listMarque.find(cat => cat._id === valMarque);
        const marque = {
          id: valeur._id,
          designation: valeur.designation
        };
        let prix_vente=prixVenteField.current.value.trim()
        prix_vente=prix_vente.replace(",", ".");

        let prix_achat=prixAchatField.current.value.trim()
        prix_achat=prix_achat.replace(",", ".");

        let qte_pack=qtePackField.current.value.trim()
        qte_pack=qte_pack.replace(",", ".");

        const tva = 20;
        const stock = 0;

        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}new/produit/${id}/${apitoken}`,
                {
                    designation,
                    marque,
                    categorie,
                    prix_vente,
                    prix_achat,
                    qte_pack,
                    tva,
                    stock,
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const data={_id,designation,marque,categorie,prix_vente,prix_achat,qte_pack,tva,stock};
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    handleClose();
                    
                }
            else if(response.data.status==="exist")
             {
                alert("Produit déja existant");
            }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Ajouter un produit</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez remplir la fiche du nouveau produit
          </DialogContentText>
          <TextField sx={{ marginTop: '20px' }}
            autoFocus
            required
            margin="dense"
            id="name"
            name="marque"
            label="Désignation"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={designationField}
          />
          <Box mt={2}>
          <FormControl fullWidth required>
          <InputLabel id="demo-simple-select-label">Marque</InputLabel>
          <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valMarque}
          label="Marque"
          onChange={handleMarqueChange}
        >
          {listMarque.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.designation}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
          </Box>

          <Box mt={2}>
          <FormControl fullWidth required>
          <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
          <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCategorie}
          label="Catégorie"
          onChange={handleCategorieChange}
        >
          {listCat.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.designation}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
          </Box>
          
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="prix achat"
            label="Prix d'achat (unité) (H.T)"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={prixAchatField}
          />
          </Box>

          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="prix vente"
            label="Prix de vente (unité) (H.T)"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={prixVenteField}
          />
          </Box>

          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="qte pack"
            label="Qte/Pack"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={qtePackField}
          />
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
