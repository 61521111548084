import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current} from './chargesSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Modifier() {
    const userData = useSelector(state => state.userData);
    const Chargedata = useSelector(state => state.chargesData);
    const currentelement = Chargedata.current;
    const showModal = Chargedata.modalModifShow;

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();


    const [selectedDate, setSelectedDate] = useState('');
    const [valFrequence, setvalFrequence] = useState('');
    const [valCategorie, setvalCategorie] = useState('');

    const formatDateToIso = (dateString) => {
      const formattedDate = new Date(`${dateString}T00:00:00Z`);
      return formattedDate.toISOString();
    };

  const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };

    const handleCategorieChange = (event) => {
      setvalCategorie(event.target.value);
    };

    const formatDateToFrench = (dateString) => {
      try {
      const [date] = dateString.split('T');
      const [year, month, day] = date.split('-');
      return `${year}-${month}-${day}`;
      }
      catch (error) {
        return ""    
      }
    };

    const montantField = useRef('');
    const designationField = useRef('');
    const dateField=useRef('')

      const handleClose = () => {
        dispatch(modalModifShow(false));
      };
      const Ajouter =async()=>{
        let idcharge =currentelement._id;
        const idperp = currentelement.idcharge;
        const categorie = valCategorie
        const frequence = currentelement.frequence
        const designation=designationField.current.value.trim()
        let montant=montantField.current.value
        montant=montant.replace(",", ".")
        const date=formatDateToIso(dateField.current.value)

        if(designation===""){
            alert("Champ désignation vide");
            return
        }
        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
          if(frequence ==="Perpétuelle"){
            const response1 = await axios.post(`${apiUrl}edit/chargePerpetuelle/${id}/${apitoken}/${idperp}`,{
                categorie,
                montant,
                designation,
            },
            {
              timeout: 20000,
            });
        }
            const response = await axios.post(`${apiUrl}edit/charge/${id}/${apitoken}/${idcharge}`,
                {
                  categorie,
                  montant,
                  designation,
                  date,

                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                  const data={idcharge,categorie,montant,designation,date};
                  dispatch(modalModifShow(false));
                  dispatch(modif(true));
                  dispatch(update(data));
                    
                }
            setChargement(false)
      }
      catch (error){
        // navigate('/');
      }
    }
  
    useEffect(() =>{
      if (Object.keys(currentelement).length > 0) {
        setvalCategorie(currentelement?.categorie)
        setvalFrequence(currentelement?.frequence)
        setSelectedDate(formatDateToFrench(currentelement?.date))
      }
      },[showModal]);

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Modifier une charge</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez modifier la fiche de la charge
          </DialogContentText>
          <TextField sx={{ marginTop: '20px' }}
            autoFocus
            required
            margin="dense"
            id="name"
            name="designation"
            label="Désignation"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={designationField}
            defaultValue={currentelement.designation}
          />
          <Box mt={2}>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCategorie}
          label="Catégorie"
          onChange={handleCategorieChange}
        >
          <MenuItem value="Achats">Achats</MenuItem>
          <MenuItem value="Frais généreaux">Frais généreaux</MenuItem>
          <MenuItem value="Véhicules">Véhicules</MenuItem>

          </Select>
          </FormControl>
          </Box>
          <Box mt={2}>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Fréquence</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valFrequence}
          label="Fréquence"
          disabled
        >
          <MenuItem value="Ponctuelle">Ponctuelle</MenuItem>
          <MenuItem value="Perpétuelle">Perpétuelle</MenuItem>

          </Select>
          </FormControl>
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="Montant"
            label="Montant H.T"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={montantField}
            defaultValue={currentelement.montant}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="date"
            name="date"
            label="Date"
            type="date"
            fullWidth
            variant="outlined"
            inputRef={dateField}
            value={selectedDate}
            onChange={handleDateChange}
          />
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
