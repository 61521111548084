import { HttpsOutlined } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardContent, Container, Divider, InputAdornment, LinearProgress, Snackbar, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function Setup2fa() {

    const [openAlert, setopenAlert] = useState(false);
    const [chargement,setChargement] = useState(false);
    const id = useSelector(state => state.userData.id);
    const QrKey=useRef('');
    const CodeField =useRef('');
    const apiUrl = process.env.REACT_APP_API_URL;

    const [imageSrc, setImageSrc] = useState(null);
    const navigate = useNavigate();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setopenAlert(false);
      };

      const Coupler=async () => {
        setChargement(true)
        const key=QrKey.current
        const code = CodeField.current.value
        try {
        const response = await axios.post(`${apiUrl}coupler/${id}/${key}/${code}`,

            {
                timeout: 20000,
              }
        );
          if(response.data.status==="ok"){
            const token = response.data.token
            Cookies.set('token', token, { 
                expires: 30,
                secure: true, 
                sameSite: 'Strict' 
              })
              navigate('/home');
          }
          else {
            setopenAlert(true)
            setChargement(false)
          }
        }
     catch (error) {
        console.error('Error:', error);
    }
    }
      
    const GenererQr=async () => {
        try {
            const key = QrKey.current
            const imageUrl = `${apiUrl}generate/qrcode/${id}/${key}`
            const response = await axios.get(imageUrl, { responseType: 'blob' });
            const imageBlob = new Blob([response.data]);
            const imageObjectURL = URL.createObjectURL(imageBlob);
            setImageSrc(imageObjectURL);

        }
        catch (error) {
            console.error('Error:', error);    
        }
        }

        const GenererKey=async () => {
            try{
                const response = await axios.post(`${apiUrl}generate/key/${id}`);
                const key = response.data.key
                QrKey.current=key
                GenererQr()
        
            }
            catch (error) {
                console.error('Error:', error);    
            }
            }

            useEffect(() =>{
                GenererKey();
                },[]);

  return (
    <Container maxWidth="md">

<Snackbar open={openAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Code incorrecte. Veuillez réessayer
        </Alert>
      </Snackbar>

      <Box mt={5} >
      <Card >
      { chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
    <CardContent >

    <Typography variant="h4" align='center' color="primary">
    Configuration de l'authentification à deux facteurs
        </Typography>
        
        <Box mt={4} >
        <Typography variant="p" align='center' >
        L'authentification à deux facteurs (2FA) ajoute une couche supplémentaire de sécurité à votre compte en vous demandant d'entrer un code provenant de votre appareil en plus de votre mot de passe lorsque vous vous connectez.
        </Typography>
        </Box>
        <Box mt={1} >
        <Typography variant="p" align='center' >
        Pour configurer la 2FA, vous devrez installer une application 2FA sur votre appareil. Il existe de nombreuses applications 2FA disponibles, telles que Google Authenticator, Authy et Microsoft Authenticator.
        </Typography>
        </Box>
        <Box mt={1} >
        <Typography variant="p" align='center' >
        Une fois que vous avez installé une application 2FA, vous devrez scanner le code QR ci-dessous en utilisant l'application. Le code QR contient une clé secrète qui sera utilisée pour générer les codes 2FA.
        </Typography>
        </Box>
        <Box

      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box mt={2} display="flex" justifyContent="center">
        <img src={imageSrc} width="200" height="200" alt="QR Code" />
      </Box>
    </Box>
        
    <Container maxWidth="sm">
        <Typography variant="h6" mt={2} mb={2} align='center' >
        Veuillez entrer le code de votre application 2FA
        </Typography>
        <Divider/>
        <Box mt={4} >
        <TextField
        required
        inputRef={CodeField}
        id="input-with-icon-textfield"
        label="Code 2FA"
        size='normal'
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HttpsOutlined />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
        </Box>

        <Box mt={4} >

    <Button variant="contained" fullWidth size='large' color="success" onClick={Coupler}>
    Soumettre
  </Button>    
    
</Box>
</Container>
    </CardContent>
        </Card>
      </Box>
    </Container>  )
}
