import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current } from './clientSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Modifier() {
    const userData = useSelector(state => state.userData);
    const clientsData = useSelector(state => state.clientsData);
    const showModal = clientsData.modalModifShow;
    const currentelement = clientsData.current;
    const listSecteur=clientsData.listSecteur
    const [valSecteur, setvalSecteur] = useState('');
    const [valBanque, setvalBanque] = useState('');
    const [valCategorie, setvalCategorie] = useState('');
    const adresseField = useRef('')

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const nomField = useRef('')
    const prenomField = useRef('')
    const telField = useRef('')
    const villeField = useRef('')
    const iceField = useRef('')
    const compteField = useRef('')

    const handleSecteurChange = (event) => {
      setvalSecteur(event.target.value);
    };

    const handleBanqueChange = (event) => {
      setvalBanque(event.target.value);
    };
    const handleCategorieChange = (event) => {
      setvalCategorie(event.target.value);
    };

    const ChampUpdate = () =>{
      const _id=currentelement._id;
      const nom=nomField.current.value;
      const tel=telField.current.value;
      const adresse=adresseField.current.value;
      const categorie = valCategorie
      const prenom=prenomField.current.value;
      const ice=iceField.current.value;
      const ville=villeField.current.value;
      const valeur = listSecteur.find(secteur => secteur._id === valSecteur);
      const banque = valBanque
      const compte = compteField.current.value
      const secteur = {
        id: valeur._id,
        designation: valeur.designation
      }
      const data={_id,nom,prenom,tel,adresse,categorie,ice,ville,secteur,banque,compte};
      dispatch(current(data)); 
    }


      const handleClose = () => {
        dispatch(modalModifShow(false));
      };
      const Ajouter =async()=>{
        const nom =nomField.current.value.trim()
        const prenom = prenomField.current.value.trim()
        if(nom===""){
          alert("Champ nom vide");
          return

        }

        const ville = villeField.current.value
        const ice = iceField.current.value
        const tel = telField.current.value
        const adresse = adresseField.current.value
        const categorie = valCategorie

        const banque= valBanque
        const compte = compteField.current.value

        const valeur = listSecteur.find(secteur => secteur._id === valSecteur);
        const secteur = {
          id: valeur._id,
          designation: valeur.designation
        };

        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        const idclient = currentelement._id
        try {
            const response = await axios.post(`${apiUrl}edit/client/${id}/${apitoken}/${idclient}`,
                {
                  nom,
                  prenom,
                  tel,
                  adresse,
                  categorie,
                  ice,
                  ville,
                  secteur,
                  banque,
                  compte
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const data={idclient,nom,prenom,tel,adresse,categorie,ice,ville,secteur,banque,compte};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));
                    
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

    useEffect(() =>{
      if(Object.keys(currentelement).length>0)
      {
      setvalSecteur(currentelement.secteur["id"]);
      setvalBanque(currentelement.banque)
      setvalCategorie(currentelement.categorie)
    }
      },[currentelement]);
  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Modifier Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez modifier la fiche du client
          </DialogContentText>
          <TextField sx={{ marginTop: '20px' }}
            autoFocus
            required
            margin="dense"
            id="nom"
            name="nom"
            label="Nom"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={nomField}
            value={currentelement.nom}
            onChange={ChampUpdate}
          />
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="prenom"
            name="prenom"
            label="Prénom"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={prenomField}
            value={currentelement.prenom}
            onChange={ChampUpdate}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="tel"
            name="tel"
            label="Tel."
            type="text"
            fullWidth
            variant="outlined"
            inputRef={telField}
            value={currentelement.tel}
            onChange={ChampUpdate}
          />
          </Box>
          <Box mt={2}>
            <TextField
            autoFocus
            margin="dense"
            id="adresse"
            name="adresse"
            label="Adresse"
            type="text"
            fullWidth
            variant="outlined"
            value={currentelement.adresse}
            inputRef={adresseField}
            onChange={ChampUpdate}
          />
          </Box>
          <Box mt={2}>

<FormControl fullWidth sx={{ minWidth: 150 }}>
<InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
<Select

        labelId="demo-simple-select-label"
        id="demo-simple-select"
        fullWidth
        value={valCategorie}
        label="Catégorie"
        onChange={handleCategorieChange}
        >
        <MenuItem value="AG">AG</MenuItem>
        <MenuItem value="Café">Café</MenuItem>
        <MenuItem value="Restaurant">Restaurant</MenuItem>
        <MenuItem value="Dépôt de lait">Dépôt de lait</MenuItem>
        <MenuItem value="Kiosque">Kiosque</MenuItem>
        <MenuItem value="Boulangerie">Boulangerie</MenuItem>
        </Select>
        </FormControl>

</Box>
          <Box mt={2}>
            <TextField
            autoFocus
            margin="dense"
            id="ville"
            name="ville"
            label="Ville"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={villeField}
            value={currentelement.ville}
            onChange={ChampUpdate}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="ice"
            name="ice"
            label="ICE"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={iceField}
            value={currentelement.ice}
            onChange={ChampUpdate}
          />

          </Box>
          <Box mt={2}>

         <FormControl fullWidth sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Secteur</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         fullWidth
         value={valSecteur}
         label="Marque"
         onChange={handleSecteurChange}
       >
         {listSecteur.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>

</Box>
<Box mt={2}>

<FormControl fullWidth sx={{ minWidth: 150 }}>
<InputLabel id="demo-simple-select-label">Banque</InputLabel>
<Select

labelId="demo-simple-select-label"
id="demo-simple-select"
fullWidth
value={valBanque}
label="Banque"
onChange={handleBanqueChange}
>

<MenuItem value="AL AKHDAR BANK">AL AKHDAR BANK</MenuItem>
<MenuItem value="AL BARID BANK">AL BARID BANK</MenuItem>
<MenuItem value="ARAB BANK">ARAB BANK</MenuItem>
<MenuItem value="ATTIJARIWAFA BANK">ATTIJARIWAFA BANK</MenuItem>
<MenuItem value="BANK AL YOUSR">BANK AL YOUSR</MenuItem>
<MenuItem value="BANK ASSAFA">BANK ASSAFA</MenuItem>
<MenuItem value="BANK OF AFRICA">BANK OF AFRICA</MenuItem>
<MenuItem value="BANQUE CENTRALE POPULAIRE">BANQUE CENTRALE POPULAIRE</MenuItem>
<MenuItem value="BMCI">BMCI</MenuItem>
<MenuItem value="BTI BANK">BTI BANK</MenuItem>
<MenuItem value="CDG CAPITAL">CDG CAPITAL</MenuItem>
<MenuItem value="CFG BANK">CFG BANK</MenuItem>
<MenuItem value="CIH BANK">CIH BANK</MenuItem>
<MenuItem value="CITIBANK MAGHREB">CITIBANK MAGHREB</MenuItem>
<MenuItem value="CREDIT AGRICOLE">CREDIT AGRICOLE</MenuItem>
<MenuItem value="CREDIT DU MAROC">CREDIT DU MAROC</MenuItem>
<MenuItem value="DAR EL AMANE">DAR EL AMANE</MenuItem>
<MenuItem value="SOCIÉTÉ GÉNÉRALE">SOCIÉTÉ GÉNÉRALE</MenuItem>
<MenuItem value="UMNIA BANK">UMNIA BANK</MenuItem>
</Select>
</FormControl>

</Box>

<Box mt={2}>
<TextField
            autoFocus
            margin="dense"
            id="compte"
            name="compte"
            label="N° compte"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={compteField}
            value={currentelement.compte}
            onChange={ChampUpdate}
          />
</Box>

        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
