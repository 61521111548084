import { Box, Chip, Container, FormControl, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField, Typography } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

export default function PdaStock() {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const userData = useSelector(state => state.userData);

      const [Chargement, setChargement] = useState(false);
      const [listProduits, setListProduits] = useState([]);
      const [valCategorie, setCategorie] = useState('Tout');
      const [valMarque, setMarque] = useState('Tout');
      const [listMarque,setListMarque] = useState([]);
      const [listCat,setListCat] = useState([]);
      const [rechercheInput,setRchercheInput] = useState('');

      const rechercheField = useRef('')


      const handleCategorieChange = (event) => {
        setCategorie(event.target.value);
      }

      const handleMarqueChange = (event) => {
        setMarque(event.target.value);
      };
  
  
      const id = userData.id;
      const apitoken = userData.apitoken;
      const apiUrl = process.env.REACT_APP_API_URL;

      const navigate = useNavigate();

      const GetPack =(nb_unite,qte_pack) => Math.floor(nb_unite / qte_pack)
      const GetUnite =(nb_unite,qte_pack) => nb_unite % qte_pack

      function ShowList() {
        if(listProduits.length > 0){
          let listFiltre=listProduits
          if(rechercheInput.length > 2) {
            listFiltre = listFiltre.filter(produit => produit.designation.toLowerCase().includes(rechercheInput.toLowerCase()))
        }

        if(valMarque!=="Tout") {
            listFiltre = listFiltre.filter(produit => produit.marque === valMarque)
        }
  
        if(valCategorie!=="Tout") {
          listFiltre = listFiltre.filter(produit => produit.categorie === valCategorie)
      }
          return listFiltre.map(Produit => {
            return(
              <StyledTableRow key={Produit._id}>
              <StyledTableCell component="th" scope="row" align="center">
              <Typography variant="h6" align='center' color="secondary">
              {Produit.designation}
                </Typography>
                <Box mt={1}>
<Chip sx={{ marginTop: '2px',marginLeft:'10px' }} label={`PACKS : ${GetPack(Produit.totalunite,Produit.qte_pack)}`} />
        </Box>
        <Box mt={1}>

<Chip sx={{ marginTop: '2px',marginLeft:'10px' }} label={`UNITES : ${GetUnite(Produit.totalunite,Produit.qte_pack)}`} />
        </Box>
              </StyledTableCell>
              
            </StyledTableRow>
            )
          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

          const getMarque=async () => {
            try {
            const response = await axios.post(`${apiUrl}marques/${id}/${apitoken}`,
              {
                timeout: 20000,
              }
            );
            setListMarque(response.data);
            }
         catch (error) {
          navigate('/');    
        }
        }

        const getCategorie=async () => {
          try {
          const response = await axios.post(`${apiUrl}categories/${id}/${apitoken}`,
            {
              timeout: 20000,
            }
          );
          setListCat(response.data);
          }
       catch (error) {
        navigate('/');    
      }
      }
          const getStock=async () => {
            setChargement(true)
            try {
            const response = await axios.post(`${apiUrl}pda/stockcamion/${id}/${apitoken}`,
              {
                timeout: 20000,
              }
            );
    
            const stockcamion = response.data.data["produits"]
  
            setListProduits(stockcamion);
            }
         catch (error) {
          navigate('/');    
        }
        setChargement(false)
        }

        const Recherche=(event) =>{
          const recherche=rechercheField.current.value
          if(recherche!==rechercheInput){
            setRchercheInput(recherche)
             }
        }
      
        useEffect(() =>{
          getStock();
          getMarque();
          getCategorie();
            },[]);
  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Stock Camion
        </Typography>

        <Box mt={2}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} fullWidth />
         :
      <FormControl fullWidth size='small'>
          <InputLabel id="demo-simple-select-label">Catégories</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCategorie}
          label="Catégories"
          onChange={handleCategorieChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {listCat.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.designation}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
}
</Box>
<Box mt={2}>
{Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={56} fullWidth />
         :
<FormControl fullWidth size='small'>
          <InputLabel id="demo-simple-select-label">Marques</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select2"
          value={valMarque}
          label="Marques"
          onChange={handleMarqueChange}
        >
          <MenuItem value='Tout'>Tout</MenuItem>
          {listMarque.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.designation}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
}
</Box>
<Box mt={2}>
{Chargement ? 
        <Skeleton  variant="rounded" height={56} fullWidth />
         :
        <TextField
        onKeyUp={Recherche}
        inputRef={rechercheField}
        id="input-with-icon-textfield"
        label="Recherche"
        size='small'
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      
       }
</Box>

        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Liste des produits</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}

    </Container>
  )
}
