import { Box, Card, Container, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

export default function AdminStats() {

  const userData = useSelector(state => state.userData);
  const id = userData.id;
  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const [ChiffreAffaire, setChiffreAffaire] = useState("--");
  const [Benifice, setBenifice] = useState("--");
  const [Tresor, setTresor] = useState("--");
  const [Charges, setCharges] = useState("--");
  const [ValeurStock, setValeurStock] = useState("--");
  const [ValeurCredit, setValeurCredit] = useState("--");
  const [ValeurCheques, setValeurCheques] = useState("--");
  const [ValeurClient, setValeurClient] = useState("--");
  


  const currentDate = new Date();
    // Obtenir le début du mois
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    // Obtenir la fin du mois
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);

    const getChiffre=async () => {
      try {
      const response = await axios.post(`${apiUrl}statistiques/chiffre/${id}/${apitoken}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`,
        {
          timeout: 20000,
        }
      );
      setChiffreAffaire(response.data.total.toFixed(2));
      }
   catch (error) {  
  }
  }

  const getBenifice=async () => {
    try {
    const response = await axios.post(`${apiUrl}statistiques/benifice/${id}/${apitoken}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`,
      {
        timeout: 20000,
      }
    );
    const benef = response.data.total
    setBenifice((benef-Charges).toFixed(2))
    }
 catch (error) {  
}
}

  const getTresor=async () => {
    try {
    const response = await axios.post(`${apiUrl}statistiques/versements/${id}/${apitoken}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`,
      {
        timeout: 20000,
      }
    );

    const totalVersements = response.data.total

    const response2 = await axios.post(`${apiUrl}statistiques/chequespaye/${id}/${apitoken}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`,
    {
      timeout: 20000,
    }
  );
  const totalCheques = response2.data.total

    setTresor((totalCheques+totalVersements).toFixed(2));
    }
 catch (error) { 
}
}

const getValeurCheques=async () => {
  try {
  const response = await axios.post(`${apiUrl}statistiques/valeurcheque/${id}/${apitoken}`,
    {
      timeout: 20000,
    }
  );
  setValeurCheques(response.data.total.toFixed(2));
  }
catch (error) {
}
}

const getValeurStock=async () => {
  try {
  const response = await axios.post(`${apiUrl}statistiques/valeurstock/${id}/${apitoken}`,
    {
      timeout: 20000,
    }
  );
  setValeurStock(response.data.total.toFixed(2));
  }
catch (error) {
}
}

const getValeurCredit=async () => {
  try {
  const response = await axios.post(`${apiUrl}statistiques/valeurcredit/${id}/${apitoken}`,
    {
      timeout: 20000,
    }
  );
  setValeurCredit(response.data.total.toFixed(2));
  }
catch (error) {  
}
}

const getValeurCharges=async () => {
  try {
  const response = await axios.post(`${apiUrl}statistiques/valeurcharges/${id}/${apitoken}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`,
    {
      timeout: 20000,
    }
  );
  const charges = response.data.total
  setCharges(charges.toFixed(2));
  }
catch (error) {  
}
}

const getNbClient=async () => {
  try {
  const response = await axios.post(`${apiUrl}statistiques/nbclient/${id}/${apitoken}/${startOfMonth.toISOString()}/${endOfMonth.toISOString()}`,
    {
      timeout: 20000,
    }
  );
  setValeurClient(response.data.total);
  }
catch (error) {  
}
}


  useEffect(() =>{
    getChiffre();
    getTresor();
    getValeurCheques();
    getValeurStock();
    getValeurCredit();
    getValeurCharges();
    getNbClient()
    },[]);
  
    useEffect(() =>{
      if (Charges !== "--") {
        getBenifice()  
      }

      },[Charges]);

  return (
    <Container maxWidth="lg" >
<Box mt={5} sx={{ flexGrow: 1 }} width={'100%'} >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

        
        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/sales.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#00B5B8',fontWeight:'bold'}}>
            {ChiffreAffaire} Dhs
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Chiffre du mois
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>
         
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/profit.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#16D39A',fontWeight:'bold'}}>
            {Benifice} Dhs
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Bénefice du mois
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>        
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/tresor.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#FF7588',fontWeight:'bold'}}>
            {Tresor} Dhs
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Trésorerie du mois
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>
        </Grid>
        
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/spending.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#FFA808',fontWeight:'bold'}}>
            {Charges} Dhs
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Charges du mois
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/check.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#FF7588',fontWeight:'bold'}}>
            {ValeurCheques} Dhs
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Valeur chèques
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/credit.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#FFA808',fontWeight:'bold'}}>
            {ValeurCredit} Dhs
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Valeur crédit
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/stock2.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#16D39A',fontWeight:'bold'}}>
            {ValeurStock} Dhs
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Valeur stock
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

        <Card variant="outlined" sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
        <img src={`${process.env.PUBLIC_URL}/customer.png`} width={60} height={60}  alt="promo" />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >

        <Typography variant="h5" align='right' sx={{color:'#16D39A',fontWeight:'bold'}}>
            {ValeurClient}
            </Typography> 

            <Typography variant="h6" align='right' sx={{color:'#6c757d',fontWeight:'bold'}}>
            Nombre de clients
            </Typography> 
            
        </Grid>
        </Grid>
        </Card>
        </Grid>
      </Grid>
    </Box>
    </Container>
  )
}
