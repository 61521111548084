import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,addOne} from './chequeSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Ajouter() {
    const userData = useSelector(state => state.userData);
    const Chequedata = useSelector(state => state.chequesData);
    const listClients = Chequedata.listClients;    
    const showModal = Chequedata.modalShow;
    const [ValClient, setValClient] = useState('');

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const numeroField=useRef('')
    const dateField=useRef('')
    const montantField=useRef('')
    const remarqueField = useRef('')
    const clientField=useRef('')

    const handleClientChange = (event) => {
      setValClient(event.target.value);
    };
      const handleClose = () => {
        dispatch(showmodal(false));
      };
      const Ajouter =async()=>{
        const numero=numeroField.current.value.trim()
        const date=formatDateToIso(dateField.current.value)
        let montant=montantField.current.value
        montant=montant.replace(",", ".")

        const remarque=remarqueField.current.value

        const valeur = listClients.find(item => item._id === ValClient);

        const client = {
          id: valeur._id,
          nom: valeur.nom,
          prenom: valeur.prenom,
          client_code : valeur.client_code,
        };
        const etat = "Enregistré"

        if(montant===""){
          alert("Champ montant vide");
          return
      }

        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}new/cheque/${id}/${apitoken}`,
                {
                  client,
                  numero,
                  montant,
                  remarque,
                  etat,
                  date,
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                  const _id=response.data.id;
                  const date_ajout=response.data.date_ajout;
                  const data={_id,client,numero,montant,remarque,etat,date,date_ajout};
                  dispatch(showmodal(false));
                  dispatch(ajout(true));
                  dispatch(addOne(data));

                    
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;

    const [selectedDate, setSelectedDate] = useState(todayDate);

    const formatDateToIso = (dateString) => {
      const formattedDate = new Date(`${dateString}T00:00:00Z`);
      return formattedDate.toISOString();
    };

  const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Ajouter un nouveau chèque</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez remplir la fiche du nouveau chèque
          </DialogContentText>
          <Box mt={2}>

         <FormControl fullWidth sx={{ minWidth: 150 }}>
         <InputLabel id="demo-simple-select-label">Clients</InputLabel>
         <Select
         
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         fullWidth
         value={ValClient}
         label="Clients"
         onChange={handleClientChange}
       >
         {listClients?.map((item) => (
         <MenuItem key={item._id} value={item._id}>
          
           {item?.nom} {item?.prenom} / {item?.client_code} / {item.secteur?.designation}
         </MenuItem>
       ))}
         </Select>
         </FormControl>

</Box>
<Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="numero"
            name="numero"
            label="Numéro de chèque"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={numeroField}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="montant"
            name="mantant"
            label="Montant"
            type="number"
            fullWidth
            variant="outlined"
            inputRef={montantField}
          />
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="date"
            name="date"
            label="Date d'encaissement"
            type="date"
            fullWidth
            variant="outlined"
            inputRef={dateField}
            value={selectedDate}
            onChange={handleDateChange}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="remarque"
            name="remarque"
            label="Remarque"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={remarqueField}
          />
          </Box>
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
