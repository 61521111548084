import { Alert, Box, Chip, Container, FormControl, InputAdornment, InputLabel, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector,useDispatch } from 'react-redux';
import {getList,current,ajoutsuccess} from './venteSlice';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';


export default function RechercheClient() {
  const userData = useSelector(state => state.userData);
  const [Chargement, setChargement] = useState(false);

  const id = userData.id;
  const listSecteur = userData.secteurs

  const apitoken = userData.apitoken;
  const apiUrl = process.env.REACT_APP_API_URL;

  const venteData=useSelector(state => state.PdanewventeData);
  const ajoutSuccess = venteData.ajoutSuccess
  const listClient=venteData.list
  const [rechercheInput,setRchercheInput] = useState('');
  const [rechercheInput2,setRchercheInput2] = useState('');
  const [valSecteur, setvalSecteur] = useState('Tout');
  const [valCategorie, setvalCategorie] = useState('Tout');


  const rechercheNomField = useRef('')
  const recherchePrenomField = useRef('')

  const handleSecteurChange = (event) => {
    setvalSecteur(event.target.value);
  };

  const handleCategorieChange = (event) => {
    setvalCategorie(event.target.value);
  };

  const dispatch= useDispatch();
  const navigate = useNavigate();

  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(ajoutsuccess(false));
  };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    const SelectClient = (id) => {
      const valeur = listClient.find(client => client._id === id);
      dispatch(current(valeur));
      navigate('/home/nouvellevente'); 
  };

      function ShowList() {
        if(listClient.length > 0){
          let listFiltre=listClient
          if(rechercheInput.length > 2) {
            listFiltre = listFiltre.filter(client => client.nom.toLowerCase().includes(rechercheInput.toLowerCase()))
        }
        if(rechercheInput2.length > 2) {
          listFiltre = listFiltre.filter(client => client.prenom.toLowerCase().includes(rechercheInput2.toLowerCase()))
      }
      if(valSecteur!=="Tout") {
        listFiltre = listFiltre.filter(client => client.secteur["id"] === valSecteur)
    }
    if(valCategorie!=="Tout") {
      listFiltre = listFiltre.filter(client => client.categorie === valCategorie)
  }
          return listFiltre.map(Client => {
            return(
              <StyledTableRow key={Client._id} onClick={() =>SelectClient(Client._id)}>
              <StyledTableCell component="th" scope="row" align="center">
              <Typography variant="h6" align='center' color="secondary">
                {Client.nom} {Client.prenom}
                </Typography>
                <Box>
                {Client.client_code}

                <Box mt={1} width={'100%'} alignContent={'center'} justifyContent={'center'}>
          <Stack direction="row" spacing={1} justifyContent={'center'}>
         <Chip label={Client.secteur["designation"]} />
         </Stack>
          </Box>

                </Box>
                <Box mt={1}>
                <Typography variant="h7" align='center'>
                {Client.adresse}
                </Typography>
                </Box>

              </StyledTableCell>
              
            </StyledTableRow>
            )

          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }
        
          const Recherche=(event) =>{
            const nom=rechercheNomField.current.value
            const prenom=recherchePrenomField.current.value
            if(nom!==rechercheInput){
              setRchercheInput(nom)
               }
            if(prenom!==rechercheInput2){
            setRchercheInput2(prenom)
              }   
          }

      const getClient=async () => {
        setChargement(true);
        try {
        const response = await axios.post(`${apiUrl}pda/clients/${id}/${apitoken}`,
          {
            timeout: 20000,
          }
        );
        dispatch(getList(response.data));
        }
     catch (error) {
      navigate('/');    
    }
    setChargement(false);
    }

      useEffect(() =>{
        getClient();
        },[]);

  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Recherche client
        </Typography>

        <Box mt={4}>
        {Chargement ? 
        <Skeleton fullWidth  variant="rounded" height={56} />
         :
    <FormControl fullWidth size="small">
    <InputLabel id="demo-simple-select-label">Secteur</InputLabel>
    <Select

    labelId="demo-simple-select-label"
    id="demo-simple-select"
    fullWidth
    value={valSecteur}
    label="Marque"
    onChange={handleSecteurChange}
    >
    <MenuItem value='Tout'>Tout</MenuItem>
    {listSecteur?.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.designation}
    </MenuItem>
    ))}
    </Select>
    </FormControl>
    }
</Box>

<Box mt={2}>

<FormControl fullWidth size="small">
<InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
<Select

labelId="demo-simple-select-label"
id="demo-simple-select"
fullWidth
value={valCategorie}
label="Catégorie"
onChange={handleCategorieChange}
>
<MenuItem value='Tout'>Tout</MenuItem>
<MenuItem value="AG">AG</MenuItem>
<MenuItem value="Café">Café</MenuItem>
<MenuItem value="Restaurant">Restaurant</MenuItem>
<MenuItem value="dépôt de lait">dépôt de lait</MenuItem>
<MenuItem value="Kiosque">Kiosque</MenuItem>
<MenuItem value="Boulangerie">Boulangerie</MenuItem>
</Select>
</FormControl>

</Box>

        <Box mt={2} sx={{width: '100%' }}>
        {Chargement ? 
        <Skeleton fullWidth sx={{ marginTop: '20px' }}  variant="rounded" height={56} />
         :
        <TextField
        fullWidth
        onKeyUp={Recherche}
        inputRef={rechercheNomField}
        id="input-with-icon-textfield"
        label="Nom"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
        }
        </Box>
        <Box mt={2} sx={{width: '100%' }}>
        {Chargement ? 
        <Skeleton fullWidth sx={{ marginTop: '20px' }}  variant="rounded" height={56} />
         :
        <TextField
        fullWidth
        onKeyUp={Recherche}
        inputRef={recherchePrenomField}
        id="input-with-icon-textfield"
        label="Prenom"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
        }
        </Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Liste clients</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
<Snackbar open={ajoutSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Vente effectuée avec succès
        </Alert>
      </Snackbar>

        </Container>
  )
}
