import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {showpasswordmodal,modifpassword} from './userSlice';
import {Box, LinearProgress, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Settings() {
    const userData = useSelector(state => state.userData);
    const showModal = userData.modalShow;

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const currentMdpField = useRef('')
    const newMdpField = useRef('')
    const confirmMdpField = useRef('')


      const handleClose = () => {
        dispatch(showpasswordmodal(false));
      };
      const Ajouter =async()=>{
        const currentpassword=currentMdpField.current.value.trim()
        const newpassword = newMdpField.current.value.trim()
        const confirmpassword = confirmMdpField.current.value.trim()

        if (currentpassword==="" || newpassword==="" || confirmpassword===""){
          alert('Vous remplir tous les champs')
          return
      }
      if(newpassword.length<8){
        alert('Le mot de passe doit contenir au moins 8 caractères')
        return
    }
    if(newpassword!==confirmpassword){
      alert('Mot de passe incorrecte')
      return
  }
        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}edit/password/${id}/${apitoken}`,
                {
                  currentpassword,
                  newpassword,
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    dispatch(showpasswordmodal(false));
                    dispatch(modifpassword(true));
                    
                }
            else if(response.data.status==="incorrect")
             {
                alert("Mot de passe incorrecte");
            }
      }
      catch (error){
        navigate('/');
      }
      setChargement(false)
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Changement de mot de passe</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Entrez la Désignation du nouveau fournisseur.
          </DialogContentText> */}
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="curentmdp"
            name="curentmdp"
            label="Mot de passe actuel"
            type="password"
            fullWidth
            variant="outlined"
            inputRef={currentMdpField}
          />
          </Box>

          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="newmdp"
            name="newmdp"
            label="Nouveau mot de passe"
            type="password"
            fullWidth
            variant="outlined"
            inputRef={newMdpField}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="confirmmdp"
            name="confirmmdp"
            label="confirmer le nouveau mot de passe"
            type="password"
            fullWidth
            variant="outlined"
            inputRef={confirmMdpField}
          />
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}