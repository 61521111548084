import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector,useDispatch } from 'react-redux';
import { showmodel,govierge,goventes } from "./FactureSlice";
import { Box } from '@mui/material';

export default function Model() {
  const facturesData = useSelector(state => state.facturesData);
  const modalShow = facturesData.modelShow;

  const dispatch= useDispatch();
  const handleClose = () => {
    dispatch(showmodel(false));
  };

  const Vierge=() => {
    dispatch(showmodel(false));
    dispatch(govierge(true))
    }
    
  const Ventes=() => {
    dispatch(showmodel(false));
    dispatch(goventes(true))
    }

  return (
<Dialog
        open={modalShow}
        onClose={handleClose}
      >
        <DialogTitle>Nouvelle facture</DialogTitle>
        <DialogContent>
          <DialogContentText>
          veuillez choisir le type de la facture
          </DialogContentText>
          <Box display="flex" width="100%" mt={3}>
          <Button variant="contained" size='large' color="primary" onClick={Vierge}>
      modèle vièrge
        </Button>  
        <Button sx={{marginLeft:"10px"}} variant="contained" size='large' color="secondary" onClick={Ventes}>
      depuis les ventes
        </Button>    
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
  )
}
