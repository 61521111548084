import { Alert, Box, Chip, Container, FormControl, InputAdornment, InputLabel, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Apercu from './ApercuCredit';
import { useNavigate } from 'react-router-dom';
import {removesuccess,getListVentes} from './venteSlice';

export default function PDAcredits() {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    const userData = useSelector(state => state.userData);
    const id = userData.id;
    const listSecteur = userData.secteurs

    const venteData=useSelector(state => state.PdanewventeData);
    const removeSuccess = venteData.removeSuccess
    const listeVentes = venteData.listVentes
  
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;
    const [Chargement, setChargement] = useState(false);
    const [rechercheInput,setRchercheInput] = useState('');
    const [rechercheInput2,setRchercheInput2] = useState('');
    const [valSecteur, setvalSecteur] = useState('Tout');
    const [showApercu, setShowApercu] = useState(false);
    const [currentVente, setcurrentVente] = useState([]);

    const navigate = useNavigate();

  const rechercheNomField = useRef('')
  const recherchePrenomField = useRef('')

  const handleSecteurChange = (event) => {
    setvalSecteur(event.target.value);
  };

  const toggleApercu = () => {
    setShowApercu(prevState => !prevState);
  };
  const ShowApercu = (vente) => {
    setcurrentVente(vente);
    toggleApercu()
  }

  const dispatch= useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(removesuccess(false));
  };
  const Recherche=(event) =>{
    const nom=rechercheNomField.current.value
    const prenom=recherchePrenomField.current.value
    if(nom!==rechercheInput){
      setRchercheInput(nom)
       }
    if(prenom!==rechercheInput2){
    setRchercheInput2(prenom)
      }   
  }

  const getCredtis=async () => {
    setChargement(true);
    try {
    const response = await axios.post(`${apiUrl}pda/list/credits/${id}/${apitoken}`,
      {
        timeout: 20000,
      }
    );
    dispatch(getListVentes(response.data));
    }
 catch (error) {
  navigate('/');    
}
setChargement(false);
}

const formatToFrenchTime = (isoDate) => {
  const date = new Date(isoDate);

  // Options pour le formatage de la date et de l'heure en français
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Utilisation du format 24h
  };

  // Formatage de la date et de l'heure
  const formattedDate = date.toLocaleDateString('fr-FR', options);

  return `${formattedDate}`;
};
  

function ShowList() {
    if(listeVentes.length > 0){
      let listFiltre=listeVentes
      if(rechercheInput.length > 2) {
        listFiltre = listFiltre.filter(vente => vente.client["nom"].toLowerCase().includes(rechercheInput.toLowerCase()))
    }
    if(rechercheInput2.length > 2) {
      listFiltre = listFiltre.filter(vente => vente.client["prenom"].toLowerCase().includes(rechercheInput2.toLowerCase()))
  }
  if(valSecteur!=="Tout") {
    listFiltre = listFiltre.filter(vente => vente.secteur["id"] === valSecteur)
}
      return listFiltre.map(Ventes => {
        return(
          <StyledTableRow key={Ventes._id} onClick={() =>ShowApercu(Ventes)}>
          <StyledTableCell component="th" scope="row" align="center">
          <Typography variant="h6" align='center' color="secondary">
            {Ventes.client["nom"]}  {Ventes.client["prenom"]}
            </Typography>
            <Box> {Ventes.client["client_code"]}</Box>

            <Box mt={1}>{formatToFrenchTime(Ventes.date)}</Box>

            <Box mt={1} width={'100%'} alignContent={'center'} justifyContent={'center'}>
      <Stack direction="row" spacing={1} justifyContent={'center'}>
     <Chip label={Ventes.total+" Dhs"} />
     </Stack>
      </Box>

          </StyledTableCell>
          
        </StyledTableRow>
        )

      })
      }
      else {
        return (
          <StyledTableRow>
          <StyledTableCell align='center' component="th" scope="row">
          Pas de données
          </StyledTableCell>
        </StyledTableRow>
        )
      }
      }


  useEffect(() =>{
    getCredtis();
    },[]);
  return (
    <Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Credits
        </Typography>

        <Box mt={4}>
        {Chargement ? 
        <Skeleton fullWidth  variant="rounded" height={56} />
         :
    <FormControl fullWidth size="small">
    <InputLabel id="demo-simple-select-label">Secteur</InputLabel>
    <Select

    labelId="demo-simple-select-label"
    id="demo-simple-select"
    fullWidth
    value={valSecteur}
    label="Marque"
    onChange={handleSecteurChange}
    >
    <MenuItem value='Tout'>Tout</MenuItem>
    {listSecteur?.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.designation}
    </MenuItem>
    ))}
    </Select>
    </FormControl>
    }
</Box>

<Box mt={2} sx={{width: '100%' }}>
        {Chargement ? 
        <Skeleton fullWidth sx={{ marginTop: '20px' }}  variant="rounded" height={56} />
         :
        <TextField
        fullWidth
        onKeyUp={Recherche}
        inputRef={rechercheNomField}
        id="input-with-icon-textfield"
        label="Nom"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
        }
        </Box>
        <Box mt={2} sx={{width: '100%' }}>
        {Chargement ? 
        <Skeleton fullWidth sx={{ marginTop: '20px' }}  variant="rounded" height={56} />
         :
        <TextField
        fullWidth
        onKeyUp={Recherche}
        inputRef={recherchePrenomField}
        id="input-with-icon-textfield"
        label="Prenom"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
        }
        </Box>

        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
         <>
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
          
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Liste des ventes</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
    </>
}
<Apercu Show={showApercu} toggleApercu={toggleApercu} currentVente = {currentVente}/>

<Snackbar open={removeSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Vente supprimée avec succès
        </Alert>
      </Snackbar>
    </Container>
  )
}
