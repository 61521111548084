import { HttpsOutlined } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardContent, Container, Divider, InputAdornment, LinearProgress, Snackbar, Stack, TextField, Typography } from '@mui/material'
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';


export default function Secure() {
    const [openAlert, setopenAlert] = useState(false);
    const [chargement,setChargement] = useState(false);
    const id = useSelector(state => state.userData.id);

    const passwordField = useRef('')
    const navigate = useNavigate();
    const Soumettre =async() =>{
        setChargement(true)
        const token2fa=passwordField.current.value.trim()
        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await axios.post(`${apiUrl}secure/${id}/${token2fa}`,
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok"){
              const token = response.data.token
              Cookies.set('token', token, { 
                  expires: 30,
                  secure: true, 
                  sameSite: 'Strict' 
                })
                navigate('/home');
            }
            else {
                setopenAlert(true)
                setChargement(false)
            }
          }

          catch (error){
            navigate('/login');
          }

    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setopenAlert(false);
      };
  return (
    <Container maxWidth="sm">

<Snackbar open={openAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Code incorrecte. Veuillez réessayer
        </Alert>
      </Snackbar>

      <Box mt={5} >
      <Card >
      { chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
    <CardContent >

    <Typography variant="h4" align='center' color="primary">
        Authentification à deux facteurs
        </Typography>
        <Typography variant="h6" mt={2} mb={2} align='center' >
        Veuillez entrer le code de votre application 2FA
        </Typography>
        <Divider/>
        <Box mt={4} >
        <TextField
        required
        inputRef={passwordField}
        id="input-with-icon-textfield"
        label="Code 2FA"
        size='normal'
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HttpsOutlined />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
        </Box>

        <Box mt={4} >

    <Button variant="contained" fullWidth size='large' color="success" onClick={Soumettre}>
    Soumettre
  </Button>    
    
</Box>
    </CardContent>
        </Card>
      </Box>
    </Container>
  )
}
