import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current } from './fournisseurSlice';
import {Box, LinearProgress, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Modifier() {
    const userData = useSelector(state => state.userData);
    const fournisseurData = useSelector(state => state.fournisseursData);
    const showModal = fournisseurData.modalModifShow;
    const currentelement = fournisseurData.current;

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();

    const designationField = useRef('')
    const telField = useRef('')
    const compteField = useRef('')
    const adresseField = useRef('')
    const emailField = useRef('')

    const ChampUpdate = () =>{
      const _id=currentelement._id;
      const designation=designationField.current.value;
      const tel = telField.current.value;
      const compte = compteField.current.value;
      const adresse = adresseField.current.value;
      const email = emailField.current.value;
      const data={_id,designation,tel,compte,adresse,email};
      dispatch(current(data)); 
    }

      const handleClose = () => {
        dispatch(modalModifShow(false));
      };
      const Ajouter =async()=>{
        const designation =designationField.current.value.trim()
        const idfournisseur = currentelement._id;
        if(designation===""){
            alert("Champ fournisseur vide");
            return
        }
        const tel =telField.current.value.trim()
        const compte =compteField.current.value.trim()
        const adresse =adresseField.current.value.trim()
        const email =emailField.current.value.trim()
        setChargement(true)
        const apiUrl = process.env.REACT_APP_API_URL;
        const id = userData.id;
        const apitoken = userData.apitoken;
        try {
            const response = await axios.post(`${apiUrl}edit/fournisseur/${id}/${apitoken}/${idfournisseur}`,
                {
                  designation,
                  tel,
                  compte,
                  adresse,
                  email,                
                },
                {
                    timeout: 20000,
                  }
            );
            if(response.data.status==="ok")
                {
                    const data={idfournisseur,designation,tel,compte,adresse,email};
                    dispatch(modalModifShow(false));
                    dispatch(modif(true));
                    dispatch(update(data));
                    
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Modifier fournisseur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Entrez la Désignation du fournisseur.
          </DialogContentText>
          <Box mt={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="fournisseur"
            label="Fournisseur"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={designationField}
            value={currentelement.designation}
            onChange={ChampUpdate}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="tel"
            label="N° Tel."
            type="text"
            fullWidth
            variant="outlined"
            inputRef={telField}
            value={currentelement.tel}
            onChange={ChampUpdate}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="compte"
            label="N° Compte"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={compteField}
            value={currentelement.compte}
            onChange={ChampUpdate}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="adresse"
            label="Adresse"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={adresseField}
            value={currentelement.adresse}
            onChange={ChampUpdate}
          />
          </Box>
          <Box mt={2}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="email"
            label="E-Mail"
            type="text"
            fullWidth
            variant="outlined"
            inputRef={emailField}
            value={currentelement.email}
            onChange={ChampUpdate}
          />
          </Box>
        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
