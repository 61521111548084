import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {showmodal,ajout,addOne} from './clotureSlice';
import {Box, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function Ajouter() {
    const userData = useSelector(state => state.userData);
    const ClotureData = useSelector(state => state.cloturesData);
    const showModal = ClotureData.modalShow;
    const ListVendeur = ClotureData.listVendeurs;

    const [valVendeur, setvalVendeur] = useState('');

    const navigate = useNavigate();
    
    const [chargement,setChargement] = useState(false);
    const dispatch= useDispatch();
  
    const apiUrl = process.env.REACT_APP_API_URL;
    const id = userData.id;
    const apitoken = userData.apitoken;


    const handleVendeurChange = (event) => {
      setvalVendeur(event.target.value);
    };
    

      const handleClose = () => {
        dispatch(showmodal(false));
      };

      const formatDateToFrench = (dateString) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      };

      const Ajouter =async()=>{

        if(valVendeur===""){
            alert("Veuillez choisir un vendeur");
            return
        }
        const valeur = ListVendeur.find(vendeur => vendeur._id === valVendeur);
        const vendeur = {
          id: valeur._id,
          nom: valeur.nom
        };
        setChargement(true)

        try {
            const response = await axios.post(`${apiUrl}new/cloture/${id}/${apitoken}`,
              {vendeur},
                {
                    timeout: 20000,
                  }
            );
              if(response.data.status==="ok")
                {
                    const _id=response.data.id;
                    const date=response.data.date;
                    const data={_id,vendeur,date};
                    dispatch(ajout(true));
                    dispatch(addOne(data));
                    handleClose();
                    
                }
            setChargement(false)
      }
      catch (error){
        navigate('/');
      }
    }

  return (
<Dialog
        open={showModal}
        onClose={handleClose}
      >
{ chargement ?
    <Box >
<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="success" />
    </Stack>
    </Box>
            :
            ''
            }
        <DialogTitle>Nouvelle clôture</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Veuillez selectionner un vendeur
          </DialogContentText>
          <Box mt={2}>
          <FormControl fullWidth required>
          <InputLabel id="demo-simple-select-label">Vendeur</InputLabel>
          <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valVendeur}
          label="Vendeur"
          onChange={handleVendeurChange}
        >
          {ListVendeur.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.nom}
          </MenuItem>
        ))}
          </Select>
          </FormControl>
          </Box>

        </DialogContent>
        <DialogActions>
            {chargement ?
            <>
          <Button disabled color="error" onClick={handleClose}>Annuler</Button>
          <Button disabled color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
            :
            <>
          <Button color="error" onClick={handleClose}>Annuler</Button>
          <Button color="success" onClick={Ajouter}>Enregistrer</Button>
          </>
        }
        </DialogActions>
      </Dialog>
  )
}
