import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    modalShow : false,
    modalModifShow : false,
    ajoutOk : false,
}
const ClientPDA = createSlice({
    name : 'ClientPDAData',
    initialState,
    reducers :{
        showmodal:(state,action) =>{
            state.modalShow=action.payload
        },
        ajout:(state,action) =>{
            state.ajoutOk=action.payload
    },
    }
})
export const {showmodal,ajout} = ClientPDA.actions
export default ClientPDA.reducer;