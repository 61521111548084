import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    list :[],
    current :[],
    apercuShow : false,
    ajoutSuccess : false,
    removeSuccess : false,
    listVentes :[],
}
const Pdanewvente = createSlice({
    name : 'PdanewventeData',
    initialState,
    reducers :{
        getList:(state,action) =>{
            state.list=action.payload
        },
        current:(state,action) =>{
            state.current=action.payload
        },
        showapercu:(state,action) =>{
            state.apercuShow=action.payload
        },
        ajoutsuccess:(state,action) =>{
            state.ajoutSuccess=action.payload
        },
        removesuccess:(state,action) =>{
            state.removeSuccess=action.payload
        },
        getListVentes:(state,action) =>{
            state.listVentes=action.payload
        },
        remove: (state, action) => {    
            const id = action.payload;
            state.listVentes=state.listVentes.filter(element => element._id !== id);
          }
    }
})
export const {getList,current,showapercu,ajoutsuccess,removesuccess,getListVentes,remove} = Pdanewvente.actions
export default Pdanewvente.reducer;