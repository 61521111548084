import { Box, Button, Container, FormControl, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Skeleton, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export default function StockCamion() {
    const [listProduits, setlistProduits] = useState([]);
    const userData = useSelector(state => state.userData);
    const [Chargement, setChargement] = useState(false);
    const [valCamions, setvalCamions] = useState('');
    const [ListCamion, setListCamion] = useState([]);
    const [Progession, setProgession] = useState(false);
    const id = userData.id;
    const apitoken = userData.apitoken;
    const apiUrl = process.env.REACT_APP_API_URL;

    
    const navigate = useNavigate();

    const handleCamionChange = (event) => {
      setvalCamions(event.target.value);
      getStock(event.target.value)
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const GetPack =(nb_unite,qte_pack) => Math.floor(nb_unite / qte_pack)
      const GetUnite =(nb_unite,qte_pack) => nb_unite % qte_pack

      function ShowList() {
        if(listProduits.length > 0){
          return listProduits.map(Produit => {
            return(
              <StyledTableRow key={Produit.designation}>
              <StyledTableCell component="th" scope="row">
              {Produit.designation}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {GetPack(Produit.totalunite,Produit.qte_pack)}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
              {GetUnite(Produit.totalunite,Produit.qte_pack)}
              </StyledTableCell>
            </StyledTableRow>
            )
            
          })
          }
          else {
            return (
              <StyledTableRow>
              <StyledTableCell align='center' colSpan={3} component="th" scope="row">
              Pas de données
              </StyledTableCell>
            </StyledTableRow>
            )
          }
          }

          const getStock=async (idcamion) => {
            setProgession(true);
            try {
            const response = await axios.post(`${apiUrl}stockcamion/${id}/${apitoken}/${idcamion}`,
              {
                timeout: 20000,
              }
            );
            setlistProduits(response.data);
            }
         catch (error) {
          navigate('/');    
        }
        setProgession(false);
        }         

        const getCamions=async () => {
          setChargement(true)
          try {
          const response = await axios.post(`${apiUrl}camions/${id}/${apitoken}`,
            {
              timeout: 20000,
            }
          );
          setListCamion(response.data);
          }
        catch (error) {
        navigate('/');    
        }
        setChargement(false)
        }

        useEffect(() =>{
            getCamions();
            },[]);

  return (
<Container maxWidth="lg" sx={{ marginTop: '40px' }}>
    <Typography variant="h4" align='center' color="primary">
        Stock camion
        </Typography>

        <Box mt={2}>
{Chargement ? 
        <Skeleton  variant="rounded" height={56} width={150} />
         :
<FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">Camions</InputLabel>
          <Select
        
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valCamions}
          label="Camions"
          onChange={handleCamionChange}
        >
          {ListCamion.map((item) => (
         <MenuItem key={item._id} value={item._id}>
           {item.designation}
         </MenuItem>
       ))}
          </Select>
          </FormControl>
}
</Box>
        {Chargement ? 
        <Skeleton sx={{ marginTop: '20px' }}  variant="rounded" height={200} />
         :
        <TableContainer sx={{ marginTop: '20px' }} component={Paper}>

{ Progession ?
    <Box >
  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="success" />
      </Stack>
    </Box>
            :
            ''
            }
          
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Désignation</StyledTableCell>
            <StyledTableCell>Quantité des Packs</StyledTableCell>
            <StyledTableCell>Quantité des Unitées</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ShowList/>
        </TableBody>
      </Table>
    </TableContainer>
      }
</Container>
  )
}
